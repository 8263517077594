import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Link, TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useGoogleLogin } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';
import { GoogleIcon, Icon } from 'components/icon';
import { ENUM_ICON } from 'enums/icons';
import {
   PageMainLabel,
   PageSubLabel,
   ButtonFurther,
   ContentWrapper,
   BottomContentBox,
   HaveLinkText,
   DividerComponent,
   BasicButton,
} from 'components/common';
import PasswordTextField from 'components/password-textfield';
import { routes } from 'routes/routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { detectWebview, isValidEmail } from 'utils/utils';
import { PageWrapper } from 'wrappers';
import {
   addEmail,
   addPassword,
   authState,
   fetchLoggedUser,
   fetchVerificationCode,
   loginUserWithGoogle,
   sendMagicLink,
} from 'store/auth/auth.slice';
import { LoggedUserTypes } from 'store/auth/types';
import { BasicModal } from 'modals/basic.modal';

export function CheckYourEmail() {
   const { signupData } = useAppSelector(authState);
   const { t } = useTranslation();

   return (
      <Box
         sx={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
         }}
      >
         <ContentWrapper>
            <Box
               component='div'
               width='100%'
               display='flex'
               flexDirection='column'
               gap={2.5}
               mt={{ sm: 3.75 }}
               mb={3.75}
            >
               <PageMainLabel>{t('common:checkYourEmail.heading')}</PageMainLabel>
               <PageSubLabel>{t('common:checkYourEmail.subHeading1', { email: signupData.email })}</PageSubLabel>
               <PageSubLabel>{t('common:checkYourEmail.subHeading2')}</PageSubLabel>
            </Box>

            <BottomContentBox isFocused={false} focusedValue='calc(-100% + 642px)'>
               <SendAgainBtn />
            </BottomContentBox>
         </ContentWrapper>
      </Box>
   );
}

function SendAgainBtn() {
   const dispatch = useAppDispatch();
   const { t } = useTranslation();

   const sendAgain = () => {
      dispatch(sendMagicLink());
   };
   const waitingSeconds = 20;
   const [countdown, setCountdown] = useState(waitingSeconds);
   const [isButtonDisabled, setIsButtonDisabled] = useState(false);

   useEffect(() => {
      // Create an interval
      setIsButtonDisabled(true);
      const intervalId = setInterval(() => {
         setCountdown((prevCountdown) => {
            if (prevCountdown <= 0) {
               clearInterval(intervalId);
               setIsButtonDisabled(false);
               return prevCountdown;
            }
            return prevCountdown - 1;
         });
      }, 1000);

      return () => {
         clearInterval(intervalId);
      };
   }, []);

   return (
      <Button
         onClick={sendAgain}
         sx={{ color: '#333', textDecoration: 'underline', textTransform: 'none', fontSize: '18px' }}
         variant='text'
         disabled={isButtonDisabled}
      >
         {isButtonDisabled
            ? `${t('common:checkYourEmail.buttonCTA_disable', { countdown })}`
            : t('common:checkYourEmail.buttonCTA')}
      </Button>
   );
}
