import React, { useEffect, useRef, useState } from 'react';
import { Box, LinearProgress, Typography, useMediaQuery } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PageMainLabel, PageSubLabel, ButtonFurther, ContentWrapper } from 'components/common';
import { routes } from 'routes/routes';
import { PageWrapper } from 'wrappers';
import { ENUM_ICON } from 'enums/icons';
import { Icon, CheckedIcon, PlusIcon, SumIcon } from 'components/icon';
import {
   causesState,
   handleSelectCause,
   handleSendSelectedCauses,
   fetchCharitiesBySelectedCauses,
   fetchSelectedCauses,
} from 'store/causes/causes.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { shuffleArray } from 'utils/utils';
import type { CauseTypes } from 'store/causes/types';

import { FixedBottomNav } from 'components/common/common';
import { authState } from 'store/auth/auth.slice';
import { CausesListBox, SingleCauseBox, CheckedCause, UnCheckedCause } from './styles';
import CausesDetails from './causesDetails';
import ModalComponent from './modal';

const useGlobalStyle = process.env.REACT_APP_BONO_GLOBAL === 'true';

export function CausesPage() {
   const { t } = useTranslation();
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const { loggedUserData } = useAppSelector(authState);
   const screenSE = useMediaQuery('(max-width:599px)');
   const [sortedElements, setSortedElements] = useState<CauseTypes[]>([]);
   const { causesList, selectedCausesList } = useAppSelector(causesState);
   const [searchParams] = useSearchParams();

   // Modal state
   const [missedCausesModalIsOpen, setMissedCausesModalIsOpen] = useState<boolean>(false);

   const [overSelectedCausesModalIsOpen, setOverSelectedCausesModalIsOpen] = useState<boolean>(false);

   const isSelected = (img: CauseTypes) => {
      return selectedCausesList.some((selectedImage) => selectedImage.id === img.id);
   };

   // don't allow user to go back to password page
   window.onpopstate = () => {
      const currentLocation = window.location.href;
      if (currentLocation.includes('causes') || currentLocation.toLocaleLowerCase().includes('password')) {
         navigate(routes().causes);
      }
   };

   const handleSendData = () => {
      // Open modal when selectedCauses less than 3
      if (selectedCausesList?.length < 3) {
         setMissedCausesModalIsOpen(true);
         return;
      }

      if (selectedCausesList.length > 0) {
         dispatch(handleSendSelectedCauses()).then(() => {
            dispatch(fetchCharitiesBySelectedCauses()).then(() => {
               analytics?.track('select_causes', {
                  causes: selectedCausesList.map((cause) => cause.id),
                  causesNames: selectedCausesList.map((cause) => cause.title),
               });
               localStorage.setItem('selectedCauses', JSON.stringify(selectedCausesList));
               navigate(routes().generatePage);
            });
         });
      }
   };

   useEffect(() => {
      const sorted = shuffleArray<CauseTypes>(causesList);
      setSortedElements(sorted);
   }, [causesList]);

   const containerRef = useRef<HTMLDivElement | null>(null);
   const [isDragging, setIsDragging] = useState(false);
   const [startPosition, setStartPosition] = useState(0);

   useEffect(() => {
      const handleMouseMove = (e: MouseEvent) => {
         e.preventDefault();
         if (isDragging && containerRef.current) {
            const newDeltaX = e.pageX - startPosition;
            containerRef.current.scrollLeft -= newDeltaX;
            setStartPosition(e.pageX);
         }
      };

      const handleMouseUp = (event: MouseEvent) => {
         event.preventDefault();
         setIsDragging(false);
         document.querySelectorAll('.singleCauseBox').forEach((element) => {
            (element as HTMLDivElement).style.userSelect = '';
         });
         document.removeEventListener('mousemove', handleMouseMove);
         document.removeEventListener('mouseup', handleMouseUp);
      };

      if (isDragging) {
         document.addEventListener('mousemove', handleMouseMove);
         document.addEventListener('mouseup', handleMouseUp);
      }

      return () => {
         document.removeEventListener('mousemove', handleMouseMove);
         document.removeEventListener('mouseup', handleMouseUp);
      };
   }, [isDragging, startPosition]);

   const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      if (containerRef.current) {
         setIsDragging(true);
         setStartPosition(e.pageX);
         document.querySelectorAll('.singleCauseBox').forEach((element) => {
            (element as HTMLDivElement).style.userSelect = 'none';
         });
      }
   };

   const sessionProfile = sessionStorage.getItem('profileWasActivated');

   useEffect(() => {
      if (loggedUserData && selectedCausesList?.length === 0) {
         dispatch(fetchSelectedCauses());
      }
      document.title = `Causes | ${t('siteTitleName')}`;
   }, []);

   // Preload images
   useEffect(() => {
      if (causesList?.length > 0) {
         causesList.forEach((cause) => {
            if (cause.icon) {
               const image = new Image();
               image.src = cause.icon;
            }
         });
      }
   }, [causesList]);

   return (
      <Box
         sx={{
            width: '100%',
         }}
      >
         <Box pb={{ xs: 21.5, sm: 0 }} sx={{ justifyContent: { xs: 'start', sm: 'center' }, gap: '25px' }}>
            <Box
               sx={{ paddingLeft: 0, overflow: 'visible' }}
               component='div'
               width='100%'
               display='flex'
               flexDirection='column'
               gap={0.1}
            >
               <PageMainLabel sx={{ color: '#111111', textTransform: 'none', mx: { sm: 'auto' } }}>
                  {searchParams.get('back') === 'true' ? t('common:cuases.headingBack') : t('common:cuases.heading')}
               </PageMainLabel>
               <PageSubLabel fontSize={18} sx={{ color: '#333333', mx: { sm: 'auto' } }}>
                  {t('common:cuases.subHeading')}
               </PageSubLabel>
               <CausesListBox
                  mt={screenSE ? 0 : 3.75}
                  ref={containerRef}
                  sx={{
                     ml: -4,
                     mr: -4,
                     pl: 4,
                     pr: 4,
                     display: 'grid',
                     gridTemplateColumns: { xs: 'auto', sm: '1fr 1fr 1fr' },
                     gridTemplateRows: { xs: '1fr 1fr', sm: '1fr 1fr 1fr' },
                     gridAutoFlow: { xs: 'column', sm: 'row' },
                     width: { sm: 'max-content' },
                  }}
                  onMouseDown={handleMouseDown}
               >
                  {!!sortedElements.length &&
                     sortedElements
                        .filter((cause) => cause.active && cause.icon)
                        .map((cause, index) => (
                           <SingleCauseBox
                              isselected={String(isSelected(cause))}
                              key={cause.id}
                              index={index}
                              color={cause.impactBackground}
                              onClick={() => {
                                 if (
                                    selectedCausesList?.length > 2 &&
                                    !selectedCausesList?.some((causes) => causes.id === cause?.id)
                                 ) {
                                    setOverSelectedCausesModalIsOpen(true);
                                 }
                                 dispatch(handleSelectCause(cause));
                              }}
                           >
                              <div>{cause.title}</div>
                              {isSelected(cause) ? (
                                 <CheckedCause>
                                    <CheckedIcon />
                                 </CheckedCause>
                              ) : (
                                 <UnCheckedCause> {useGlobalStyle ? <SumIcon /> : <PlusIcon />}</UnCheckedCause>
                              )}
                           </SingleCauseBox>
                        ))}
               </CausesListBox>
            </Box>
            <Box
               sx={{
                  position: { sm: 'absolute' },
                  top: { sm: '40px' },
                  right: { sm: '0px' },
                  width: { xs: '100%', sm: '100%' },
                  transform: { sm: 'translatex(440px)' },
               }}
            >
               {!!selectedCausesList.length && (
                  <CausesDetails
                     title={selectedCausesList[selectedCausesList.length - 1]?.title}
                     desc={selectedCausesList[selectedCausesList.length - 1]?.description}
                     imageLink={selectedCausesList[selectedCausesList.length - 1]?.icon}
                     removeCause={() => dispatch(handleSelectCause(selectedCausesList[selectedCausesList.length - 1]))}
                  />
               )}
            </Box>
            <Box
               sx={{
                  position: { xs: 'fixed', sm: 'relative' },
                  bottom: { xs: 0 },
                  left: { xs: 0 },
                  right: { xs: 0 },
                  width: { sm: '100%' },
               }}
            >
               <FixedBottomNav
                  sx={{
                     display: 'flex',
                     flexDirection: 'column',
                     gap: 2,
                     height: 'auto',
                     borderTop: { xs: '1px solid #D3D3D3', sm: 'none' },
                     paddingTop: 2,
                     opacity: { xs: 0.9, sm: 1 },
                     background: { xs: '#F7F7F7', sm: 'transparent' },
                     width: '100%',
                  }}
               >
                  <Box
                     sx={{
                        width: '100%',
                        position: 'relative',
                        zIndex: 100,
                     }}
                  >
                     <Typography component='h6' fontSize={14} fontWeight={500} sx={{ textAlign: { sm: 'center' } }}>
                        {selectedCausesList?.length} {t('common:cuases.cuaseCount')}
                     </Typography>

                     <LinearProgress
                        sx={{
                           borderRadius: '30px',
                           height: '9px',
                        }}
                        variant='determinate'
                        value={selectedCausesList?.length ? selectedCausesList.length * 33.3 : 0}
                     />
                  </Box>
                  <ButtonFurther
                     sx={{
                        backgroundColor: '#262627',
                        marginBottom: 4,
                        width: { xs: '100%', sm: '100%' },
                        mx: { sm: 'auto' },
                     }}
                     endIcon={<Icon name={ENUM_ICON.ARROW_RIGHT} />}
                     onClick={handleSendData}
                  >
                     {t('common:cuases.ButtonCTA')}
                  </ButtonFurther>
               </FixedBottomNav>
            </Box>
         </Box>
         {!!missedCausesModalIsOpen && (
            <ModalComponent
               content={t('common:cuases.error.pick3')}
               onClose={() => setMissedCausesModalIsOpen(false)}
               open
            />
         )}
         {!!overSelectedCausesModalIsOpen && (
            <ModalComponent
               title={t('common:cuases.error.pick3limit')}
               content={t('common:cuases.error.pick3limitContent')}
               onClose={() => setOverSelectedCausesModalIsOpen(false)}
               open
            />
         )}
      </Box>
   );
}
