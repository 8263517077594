// ModalComponent.tsx

import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ModalComponentProps {
   open: boolean;
   onClose: () => void;
   title?: string;
   content: string;
}

function ModalComponent({ open, onClose, title, content }: ModalComponentProps) {
   const { t } = useTranslation();
   return (
      <Modal open={open} onClose={onClose} aria-labelledby='modal-title' aria-describedby='modal-description'>
         <Box
            sx={{
               position: 'absolute',
               top: '50%',
               left: '50%',
               transform: 'translate(-50%, -50%)',
               width: 350,
               bgcolor: 'background.paper',
               boxShadow: '-4px 5px',
               px: 4,
               py: 3,
               textAlign: 'center',
               borderRadius: '8px',
               border: '1px solid #000',
               outline: 'none',
            }}
         >
            {title ? <Typography sx={{ mt: 2, textAlign: 'start', fontWeight: '500' }}>{title}</Typography> : ' '}
            <Typography id='modal-description' sx={{ textAlign: 'start', fontWeight: '500' }}>
               {content}
            </Typography>
            <Button
               sx={{
                  mt: 2,
                  bgcolor: '#262627',
                  color: '#e3e3e4',
                  width: '100%',
                  borderRadius: '40px',
                  textAlign: 'center',
                  borderColor: 'white',
                  border: '1px solid',
                  textTransform: 'none',
                  mb: 1.5,
                  boxShadow: '-2px 4px black',
                  '&:hover': {
                     bgcolor: '#262627',
                     color: '#e3e3e4',
                     boxShadow: '-2px 4px black',
                  },
               }}
               onClick={onClose}
            >
               {t('common:model.ButtonCTA')}
            </Button>
         </Box>
      </Modal>
   );
}

export default ModalComponent;
