import React, { useEffect, useState } from 'react';
import * as Icons from '@mui/icons-material';

type IconProps = {
   iconName: string;
};

const allIcons: Record<string, React.ElementType> = Icons;

export function DynamicIcon({ iconName }: IconProps) {
   const [Icon, setIcon] = useState<React.ElementType | null>(null);

   useEffect(() => {
      if (allIcons[iconName]) {
         setIcon(allIcons[iconName]);
      }
   }, [iconName]);

   if (!Icon) {
      // Handle the case where Icon is not yet loaded
      return null;
   }

   return <Icon sx={{ width: '24px', height: ' 24px' }} />;
}
