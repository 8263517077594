import React from 'react';
import { styled, Box, BoxProps, Typography, TypographyProps } from '@mui/material';

export const CauseBoxItem = styled(({ children, ...props }: BoxProps) => (
   <Box component='div' borderRadius={2.5} {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   width: '100%',
   display: 'flex',
   flexDirection: 'column',
   overflow: 'hidden',
   background: theme.palette.white.main,
   border: `1px solid #0000001A`,
}));

export const CauseTitleWrapper = styled(({ children, ...props }: BoxProps) => (
   <Box component='div' display='flex' gap={1.375} alignItems='center' pl={2.5} pr={2.5} pt={2} pb={2} {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   borderBottom: `1px solid #0000001A`,
}));

export const CharitiesWrapper = styled(({ children, ...props }: BoxProps) => (
   <Box component='div' pl={2.5} pr={2.5} pt={2.5} pb={2.5} rowGap={2.5} columnGap={4} {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   display: 'grid',
   gridTemplateColumns: 'repeat(3, 1fr)',
   rowGap: 'space-between',
}));

export const CharityItemBox = styled(({ children, ...props }: BoxProps) => (
   <Box component='div' tabIndex={0} width='50px' height='40px' {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   '&:nth-of-type(1)': {
      justifySelf: 'start',
   },
   '&:nth-of-type(2)': {
      justifySelf: 'center',
   },
   '&:nth-of-type(3)': {
      justifySelf: 'end',
   },
   '&:nth-of-type(4)': {
      justifySelf: 'start',
   },
   '&:nth-of-type(5)': {
      justifySelf: 'center',
   },
   '&:nth-of-type(6)': {
      justifySelf: 'end',
   },
   '&:nth-of-type(7)': {
      justifySelf: 'start',
   },
   '&:nth-of-type(8)': {
      justifySelf: 'center',
   },
   '&:nth-of-type(9)': {
      justifySelf: 'end',
   },
}));

export const CauseTitle = styled(({ children, ...props }: TypographyProps) => (
   <Typography component='h6' fontSize={14} {...props}>
      {children}
   </Typography>
))(({ theme }) => ({
   fontWeight: 700,
   textTransform: 'capitalize',
   lineHeight: '17px',
   color: theme.palette.black.main,
}));
