import React, { useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import LockIcon from '@mui/icons-material/Lock';
import QuizIcon from '@mui/icons-material/Quiz';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import PolicyIcon from '@mui/icons-material/Policy';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { routes } from 'routes/routes';
import { clearUserData, getStripeFromUserProfile } from 'store/auth/auth.slice';
import { useAppDispatch } from 'store/hooks';
import { BasicModal } from 'modals/basic.modal';
import { FloatingUserButton } from 'components/floatingUser/floatingUser';
import BonoLogo from '../../bono-logo/bonologo';
import type { MenuItemTypes } from './type';

export function MobileHeader(): React.ReactElement {
   const [isMenuOpen, setIsMenuOpen] = useState(false);
   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
   const location = useLocation();

   useEffect(() => {
      isMenuOpen ? (document.body.style.position = 'fixed') : (document.body.style.position = 'relative');
   }, [isMenuOpen]);

   return (
      <>
         <AnimatePresence>
            {isMenuOpen && (
               <motion.div
                  initial={{ y: '-100%' }}
                  animate={{ y: 0 }}
                  exit={{ y: '-100%' }}
                  transition={{
                     ease: 'linear',
                  }}
                  style={{
                     position: 'fixed',
                     backgroundColor: '#fff',
                     width: '100%',
                     minHeight: '100vh',
                     top: 0,
                     left: 0,
                     padding: '110px 20px',
                     zIndex: 1000,
                  }}
               >
                  <NavMenu />
               </motion.div>
            )}
         </AnimatePresence>
         {isMobile ? (
            <Box
               id='mobileHeader'
               sx={{
                  backgroundColor: '#333',
                  height: '60px',
                  width: '100vw',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0 20px',
                  position: 'relative',
                  zIndex: 99999999999999,
               }}
            >
               <BonoLogo />
               <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
                  <div style={{ maxWidth: 'max-content', position: 'relative' }}>
                     <Box
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        sx={{ cursor: 'pointer', height: '24px', width: '24px' }}
                     >
                        {isMenuOpen ? (
                           <CloseIcon style={{ color: '#fff', width: '24px', height: '24px' }} />
                        ) : (
                           <>
                              {location.pathname.includes('impactDetail') ? (
                                 ''
                              ) : (
                                 <MenuIcon style={{ color: '#fff', width: '24px', height: '24px' }} />
                              )}
                              &nbsp;
                           </>
                        )}
                     </Box>
                  </div>
               </Box>
            </Box>
         ) : (
            <Box
               sx={{
                  backgroundColor: '#333',
                  height: '60px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0 20px',
                  position: 'relative',
                  zIndex: 1,
               }}
            >
               <BonoLogo />
               <FloatingUserButton />
            </Box>
         )}
      </>
   );
}

export function NavMenu() {
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const [dialog, setDialog] = useState(false);
   const userToken = localStorage.getItem('accessToken');
   const { t } = useTranslation();
   const loggedInHeaderData: MenuItemTypes[] = [
      {
         icon: <InsertChartOutlinedIcon />,
         title: `${t('common:myProfile.menuItem1')}`,
         onclick: () => goToPortfolio(),
         login: true,
         selected: true,
      },
      {
         icon: <AccountCircleRoundedIcon />,
         title: `${t('common:myProfile.menuItem3')}`,
         onclick: () => navigateToStripe(),
         login: true,
      },
      {
         icon: <ContentPasteIcon />,
         title: `${t('common:myProfile.menuItem6')}`,
         url: `${process.env.REACT_APP_MAIN_BASE_URL}/tc/`,
         login: false,
      },
      {
         icon: <PolicyIcon />,
         title: `${t('common:myProfile.menuItem7')}`,
         url: `${process.env.REACT_APP_MAIN_BASE_URL}/privacy-policy/`,
         login: false,
      },
      {
         icon: <ExitToAppIcon />,
         title: `${t('common:myProfile.menuItem8')}`,
         onclick: () => onLogout(),
         login: true,
      },
   ];

   if (t('myProfile.menuItem5') !== '') {
      const faq: MenuItemTypes = {
         icon: <QuizIcon />,
         title: `${t('myProfile.menuItem5')}s`,
         url: 'https://intercom.help/the-bono-way/en',
         login: false,
      };
      loggedInHeaderData.push(faq);
   }

   const loggedOutHeaderData = loggedInHeaderData.filter((menu) => !menu.login);

   const goToPortfolio = () => {
      sessionStorage.setItem('profileWasActivated', 'true');
      navigate(routes().portfolio);
   };

   const resetPasseord = () => {
      localStorage.clear();
      sessionStorage.clear();
      navigate(routes().forgotPassword);
   };

   const [subscriptionLoader, setSubscriptionLoader] = useState(false);

   const openUrlInNewTab = (url: string) => {
      setTimeout(() => {
         window.open(url, '_blank');
      });
   };

   const navigateToStripe = () => {
      setSubscriptionLoader(true);
      dispatch(getStripeFromUserProfile())
         .then((response) => {
            if (response.payload?.code === 'ERR_BAD_REQUEST' || response.payload?.code === 'ERR_BAD_RESPONSE') {
               if (response.payload?.response.data.message === 'customer was not found by given criteria') {
                  navigate(routes().subscription);
               } else {
                  // setup popup to the user about error happend
                  setDialog(true);
               }
            }
            if (response.payload?.sessionUrl) {
               openUrlInNewTab(response.payload?.sessionUrl);
            }
         })
         .catch((err) => {
            console.log(err);
            setDialog(true);
         })
         .finally(() => {
            setSubscriptionLoader(false);
         });
   };

   const onLogout = () => {
      localStorage.clear();
      sessionStorage.clear();
      dispatch(clearUserData());
      navigate(routes().welcome);
   };

   const handleMenuItemClick = (menu: MenuItemTypes) => {
      if (menu.url) {
         window.open(menu.url, '_blank');
      } else if (menu.onclick) {
         menu.onclick();
      }
   };

   return (
      <>
         <Box sx={{ display: 'grid', gap: { xs: '30px', md: '20px' } }}>
            {(userToken ? loggedInHeaderData : loggedOutHeaderData).map((headerElement) => {
               const { icon, title } = headerElement;
               return (
                  <Box
                     key={title}
                     onClick={() => handleMenuItemClick(headerElement)}
                     sx={{ display: 'flex', gap: { xs: '10px', md: '5px' }, cursor: 'pointer' }}
                  >
                     <Box sx={{ display: { xs: 'block', md: 'flex' } }}>{icon}</Box>
                     <Typography
                        sx={{
                           textAlign: 'center',
                           fontFamily: 'Inter',
                           color: '#333',
                           fontWeight: headerElement.selected ? '700' : '500',
                           fontSize: { xs: '16px', md: '14px' },
                           lineHeight: { xs: '19.36px', md: '24px' },
                           minWidth: 'max-content',
                        }}
                     >
                        {title}
                     </Typography>
                  </Box>
               );
            })}
         </Box>
         <BasicModal
            open={dialog}
            onClose={() => setDialog(false)}
            onNext={() => setDialog(false)}
            title='Error logging subscription'
            description='we are having a problem loading your subscription, please try again'
            buttonLabel='ok'
         />
      </>
   );
}
