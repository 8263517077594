import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Img } from 'react-image';
import { useAppSelector } from 'store/hooks';
import { causesState } from 'store/causes/causes.slice';
import { PageMainLabel, ContentWrapper, BackIconButton } from 'components/common';
import { routes } from 'routes/routes';
import { PageWrapper } from 'wrappers';
import type { CharityTypes } from 'store/causes/types';

import { CloseIcon } from 'components/icon';
import { CharityLink } from './styles';

export function CharityPage() {
   const navigate = useNavigate();
   const { pathname } = useLocation();
   const { charityList } = useAppSelector(causesState);
   const { t } = useTranslation();
   const charityEmailRegex = /^(https?:\/\/[^/]+)\/.*/;

   const [currentCharity, setCurrentCharity] = useState<CharityTypes | null>(null);

   const getBaseUrl = (url: string) => {
      const regex = /^(https?:\/\/[^/]+)\/.*/;
      const match = url.trim().match(regex);

      if (match) {
         const baseUrl = match[1];
         return baseUrl;
      }
      return '';
   };

   useEffect(() => {
      const regex = /(\d+)$/;
      const match = pathname.match(regex);
      if (match) {
         const lastNumber = Number(match[1]);
         const selected = charityList.find((charity) => charity.id === lastNumber);
         if (selected) {
            setCurrentCharity(selected);
         } else {
            navigate(routes().portfolio);
         }
      }
   }, [pathname]);

   useEffect(() => {
      document.title = `Charity Details | ${t('siteTitleName')}`;
   }, []);

   return (
      <PageWrapper logo={false} logoSpace={false}>
         <ContentWrapper
            sx={{
               display: 'flex',
               alignItems: 'start',
               justifyContent: 'start',
               height: 'max-content',
               width: '90%',
               maxWidth: '700px',
               marginX: 'auto',
               marginTop: '-55px',
            }}
         >
            <Box
               sx={{ position: 'relative' }}
               component='div'
               width='100%'
               display='flex'
               flexDirection='column'
               gap={1.25}
               mb={2.5}
            >
               <img
                  alt='Charity Logo'
                  src={currentCharity?.icon}
                  width='75px'
                  height='60px'
                  style={{ marginBottom: '10px', border: '1px solid #ccc' }}
               />
               <Typography fontSize={34} lineHeight='38px' fontWeight={900}>
                  {currentCharity?.title}
               </Typography>
               <BackIconButton
                  sx={{
                     position: 'absolute',
                     top: '6px',
                     right: '0px',
                     zIndex: '10000',
                  }}
                  onClick={() => {
                     navigate(routes().portfolio);
                  }}
               >
                  <CloseIcon />
               </BackIconButton>
               {currentCharity?.website && (
                  <CharityLink href={currentCharity.website}>{getBaseUrl(currentCharity.website)}</CharityLink>
               )}
            </Box>
            <Box
               component='div'
               borderRadius={1}
               sx={{
                  width: { xs: '100%', sm: '50%' },
                  backdropFilter: 'blur(30px)',
               }}
            >
               <Img
                  style={{
                     marginBottom: '10px',
                     width: '100%',
                     aspectRatio: 'auto',
                     borderRadius: '6px',
                  }}
                  src={currentCharity?.image || ''}
                  alt='Charity Main image'
                  loader={
                     <svg xmlns='http://www.w3.org/2000/svg' width='100%' height='200px' viewBox='0 0 180.119 139.794'>
                        <g paintOrder='fill markers stroke' transform='translate(-13.59 -66.639)'>
                           <path fill='#d0d0d0' d='M13.591 66.639H193.71v139.794H13.591z' />
                           <path
                              fill='#fff'
                              d='M118.507 133.514l-34.249 34.249-15.968-15.968-41.938 41.937h152.374z'
                              opacity='0.675'
                           />
                           <circle cx='58.217' cy='108.555' r='11.773' fill='#fff' opacity='0.675' />
                           <path fill='none' d='M26.111 77.634h152.614v116.099H26.111z' />
                        </g>
                     </svg>
                  }
               />
            </Box>
            <Typography fontSize={16}>{currentCharity?.description}</Typography>
         </ContentWrapper>
      </PageWrapper>
   );
}
