import React, { forwardRef } from 'react';

import { Box, Container } from '@mui/material';
import type { PageWrapperProps } from 'wrappers/types';
import { IconWrapperBox, BackIconButton } from 'components/common';
import { CloseIcon, Icon } from 'components/icon';
import { ENUM_ICON } from 'enums/icons';
import { IMAGE } from 'enums/images';

const useGlobalStyle = process.env.REACT_APP_BONO_GLOBAL === 'true';

function PageWrapper(
   {
      children,
      leftButton = null,
      rightButton = null,
      logo = true,
      logoSpace = true,
      topPadding = true,
      sx,
      leftButtonStyle,
   }: PageWrapperProps,
   ref: React.Ref<HTMLElement>
) {
   return (
      <Container
         component='main'
         ref={ref}
         maxWidth={false}
         sx={{
            background: ({ palette }) => palette.main.pageBackground,
            minHeight: { xs: '100%', sm: 'auto' },
            paddingTop: { xs: 0, sm: '120px' },
            ...sx,
         }}
      >
         <Box
            component='div'
            width='100%'
            height='100%'
            display='flex'
            alignItems='center'
            flexDirection='column'
            pt={topPadding ? 2.5 : 0}
         >
            <IconWrapperBox
               pb={logoSpace ? 4.75 : 1}
               sx={{
                  ...leftButtonStyle,
                  position: { xs: 'relative', sm: 'fixed' },
                  zIndex: 2000,
                  pb: { xs: '34px', sm: '0px' },
                  left: { xs: '0px', sm: '50px' },
                  top: { xs: '25px', sm: '90px' },
                  pr: { xs: '30px', sm: '50px' },
               }}
            >
               <BackIconButton
                  sx={{ visibility: leftButton ? 'visible' : 'hidden', position: 'relative' }}
                  onClick={leftButton?.onClick}
               >
                  <Icon name={ENUM_ICON.ARROW_BACK} />
               </BackIconButton>
               <Box display='flex' justifyContent='center' sx={{ width: '100%' }}>
                  {useGlobalStyle ? (
                     <Box
                        mr={{ xs: '0px', sm: '50px' }}
                        component='img'
                        src={IMAGE.SVG_LOGO}
                        alt='BONO Logo'
                        sx={{ visibility: { xs: logo ? 'visible' : 'hidden', sm: 'hidden' } }}
                     />
                  ) : (
                     <Box
                        mr={{ xs: '0px', sm: '50px' }}
                        component='img'
                        src={IMAGE.PRO_ISRAEL}
                        alt='Pro Israel Logo'
                        sx={{ visibility: { xs: logo ? 'visible' : 'hidden', sm: 'hidden' } }}
                     />
                  )}
               </Box>
               <BackIconButton sx={{ visibility: rightButton ? 'visible' : 'hidden' }} onClick={rightButton?.onClick}>
                  <CloseIcon />
               </BackIconButton>
            </IconWrapperBox>
            {children}
         </Box>
      </Container>
   );
}

export default forwardRef(PageWrapper);
