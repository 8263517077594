import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import { Trans, useTranslation } from 'react-i18next';
import { routes } from 'routes/routes';
import { PageWrapper } from 'wrappers';
import { ENUM_ICON } from 'enums/icons';
import { Icon } from 'components/icon';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
   getStripe,
   //  authState
} from 'store/auth/auth.slice';
import {
   PageMainLabel,
   PageSubLabel,
   ButtonFurther,
   // PageCaption,
   ContentWrapper,
   FixedBottomNav,
   IconCaption,
   RegLinkText,
   HaveLinkText,
   LoadingIcon,
} from 'components/common';
import { causesState } from 'store/causes/causes.slice';

import { tryParseInt } from 'utils/utils';
import { HaveLinkTextNotBold } from 'components/common/common';
import { CenteredWrapper, ActionItemsWrapper, IconWrapper, AmountBox } from './styles';

export function SubscriptionPage() {
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const { selectedCausesList } = useAppSelector(causesState);
   const startAmount = tryParseInt(process.env.REACT_APP_START_AMOUNT, 10);
   const minAmount = tryParseInt(process.env.REACT_APP_MIN_AMOUNT, 5);
   const changeAmount = tryParseInt(process.env.REACT_APP_CHANGE_AMOUNT, 5);
   const maxAmount = tryParseInt(process.env.REACT_APP_MAX_AMOUNT, 1000);
   const { t } = useTranslation();
   const messages: string[] = t('fundYourPortfolio.bullet', { returnObjects: true });
   // const { loggedUser } = useAppSelector(authState);

   const [amount, setAmount] = useState(startAmount);
   const [loading, setLoading] = useState(false);
   // const [dialog, setDialog] = useState(false);

   // useEffect(() => {
   //    const rangeToFeeMap = [
   //       { range: [0, 5], fee: 1 },
   //       { range: [10, 15], fee: 2 },
   //       { range: [20, 25], fee: 3 },
   //       { range: [30, 35], fee: 4 },
   //       { range: [40, 45], fee: 5 },
   //       { range: [50, 55], fee: 6 },
   //       { range: [60, 65], fee: 7 },
   //       { range: [70, 75], fee: 8 },
   //       { range: [80, 85], fee: 9 },
   //       { range: [90, 95], fee: 10 },
   //    ];

   //    const matchingRange = rangeToFeeMap.find((rangeObj) => {
   //       const [min, max] = rangeObj.range;
   //       return amount >= min && amount <= max;
   //    });

   //    if (matchingRange) {
   //       setServiceFee(matchingRange.fee);
   //    }
   // }, [amount]);

   const handleSubmit = () => {
      setLoading(true);
      analytics?.track('begin_checkout', {
         causes: `[${selectedCausesList.map((cause) => cause.id).join()}]`,
         value: amount,
         currency: 'USD',
      });
      dispatch(getStripe({ money: amount, serviceFee: 0 }));
   };

   const handleChaiClick = () => {
      const chaiValue = 18;
      if (amount < chaiValue) {
         setAmount(chaiValue);
      } else {
         const numOfChais = Math.floor(amount / chaiValue);
         setAmount((numOfChais + 1) * chaiValue);
      }
   };

   const changeAmountButtonClick = (increase: boolean) => {
      let multi = Math.floor(amount / changeAmount);
      // eslint-disable-next-line no-plusplus
      increase ? multi++ : multi--;
      let finalValue = multi * changeAmount;
      if (finalValue < minAmount) {
         finalValue = minAmount;
      } else if (finalValue > maxAmount) {
         finalValue = maxAmount;
      }
      setAmount(finalValue);
   };

   useEffect(() => {
      document.title = `Subscription | ${t('siteTitleName')}`;
   }, []);

   return (
      <Box
         sx={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            gap: { sm: '20px' },
            justifyContent: { xs: 'space-between', sm: 'start' },
         }}
      >
         <Box component='div' width='100%' display='flex' flexDirection='column'>
            <PageMainLabel>{t('common:fundYourPortfolio.heading')}</PageMainLabel>
            <PageSubLabel sx={{ marginBottom: '10px', fontWeight: '500' }}>
               <Trans i18nKey='common:fundYourPortfolio.subheading' components={{ 1: <br /> }} />
            </PageSubLabel>
            <CenteredWrapper>
               <ActionItemsWrapper pb={{ xs: 3.5, sm: 3 }} pt={{ xs: 3.5, sm: 7 }} width={{ sm: '300px' }} mx='auto'>
                  <IconWrapper
                     onClick={() => {
                        analytics?.track('amount_change', {
                           action: 'minus',
                           amount: amount - changeAmount,
                        });
                        changeAmountButtonClick(false);
                     }}
                     disabled={amount === minAmount}
                  >
                     <Icon name={ENUM_ICON.MINUS} />
                  </IconWrapper>
                  <AmountBox>
                     <Typography component='h6' className='SubscriptionAmount' style={{ fontWeight: 900 }}>
                        <p>$</p> <span>{amount}</span>
                     </Typography>
                  </AmountBox>
                  <IconWrapper
                     onClick={() => {
                        analytics?.track('amount_change', {
                           action: 'plus',
                           amount: amount + changeAmount,
                        });

                        changeAmountButtonClick(true);
                     }}
                     disabled={amount === maxAmount}
                  >
                     <Icon name={ENUM_ICON.PLUS} />
                  </IconWrapper>
               </ActionItemsWrapper>
               {t('fundYourPortfolio.textRow') !== '' ? (
                  <Box
                     onClick={handleChaiClick}
                     sx={{
                        color: 'rgba(71, 119, 241, 1)',
                        textDecorationLine: 'underline',
                        textAlign: 'center',
                        fontWeight: '700',
                        fontSize: '16px',
                        cursor: 'pointer',
                     }}
                  >
                     {t('fundYourPortfolio.textRow')}
                  </Box>
               ) : null}
               <Box display='flex' flexDirection='column' gap={1} maxWidth={{ sx: 'max-content' }} mx={{ xs: 'auto' }}>
                  {Array.isArray(messages) &&
                     messages
                        .filter((e) => e) // filter out empty strings
                        .map((text) => {
                           return <IconCaption key={`bullet-${text}`}>{text}</IconCaption>;
                        })}
               </Box>
            </CenteredWrapper>
         </Box>

         <Box
            sx={{
               position: { xs: 'relative', sm: 'relative' },
               bottom: { xs: 0 },
               display: 'flex',
               flexDirection: 'column',
               width: '100%',
            }}
         >
            <ButtonFurther
               sx={{
                  backgroundColor: '#262627',
                  marginBottom: 2,
                  width: { lg: '100%' },
                  mx: { lg: 'auto' },
               }}
               endIcon={<Icon name={ENUM_ICON.ARROW_RIGHT} />}
               onClick={handleSubmit}
            >
               {loading ? <LoadingIcon /> : `${t('common:fundYourPortfolio.buttonCTA')}`}
            </ButtonFurther>
            <Typography
               sx={{
                  fontFamily: 'Inter',
                  color: '#666666',
                  fontWeight: '400',
                  marginBottom: 4,
                  fontSize: '16px',
                  lineHeight: '20px',
                  textAlign: 'center',
               }}
            >
               {t('common:fundYourPortfolio.buttonCTAUnder', { interpolation: { escapeValue: false } })}
            </Typography>
         </Box>
      </Box>
   );
}
