import { Box, Skeleton, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReplyIcon from '@mui/icons-material/Reply';

import React from 'react';
import { DynamicIcon } from '../../dynamic-icon';

const defaultImg = 'https://bono-webapp-general.s3.amazonaws.com/genericProfile.png';

function PersonMain({
   tagline,
   memberDate,
   profilePhoto,
   name,
}: {
   tagline: string;
   memberDate: string;
   profilePhoto?: string;
   name: string;
}) {
   return (
      <Box sx={{ width: '100%', display: 'flex', gap: '15px' }}>
         {name.trim().length !== 0 ? (
            <Box
               component='img'
               sx={{
                  width: '80px',
                  aspectRatio: 'square',
                  border: '1px solid rgba(0, 0, 0, 0.1)',
                  borderRadius: '4px',
               }}
               alt='Profile pic'
               src={profilePhoto || defaultImg}
            />
         ) : (
            <Skeleton variant='rounded' width={82} height={72} />
         )}

         <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {name.trim().length !== 0 ? (
               <Typography
                  sx={{ fontFamily: 'Inter', color: '#333', fontWeight: 800, fontSize: '32px', lineHeight: '32px' }}
               >
                  {name}
               </Typography>
            ) : (
               <Skeleton variant='text' width={250} height={32} />
            )}

            {memberDate ? (
               <Typography
                  sx={{
                     fontFamily: 'Inter',
                     color: '#333',
                     fontWeight: 400,
                     fontSize: '16px',
                     lineHeight: '19.36px',
                     fontStyle: 'italic',
                  }}
               >
                  Member since {memberDate}
               </Typography>
            ) : (
               <Skeleton variant='text' width={250} height={19} />
            )}
         </Box>
      </Box>
   );
}

function PersonFact(props: any) {
   const {
      data: { data },
   } = props;

   return (
      <Box sx={{ width: '100%', display: 'grid', gap: '6px', gridTemplateColumns: ' repeat(3, 1fr);' }}>
         {data?.map((singleData: any) => {
            const { icon, text, subHeader, order } = singleData;

            return (
               <div key={subHeader} style={{ order }}>
                  <MediumBox icon={icon} number={text} text={subHeader} />
               </div>
            );
         })}
      </Box>
   );
}

function ProfileDescription(props: any) {
   const { data: { title, text, buttonText, CTA } = { title: '', text: '', buttonText: '', CTA: '' } } = props;
   return (
      <Box sx={{ display: 'flex', fontFamily: 'Inter' }}>
         <div style={{ backgroundColor: '#333', width: '3px' }} />
         <Box sx={{ flexGrow: 1, padding: '0 66px 0 16px', display: 'grid', gap: '15px' }}>
            <Box>
               <Typography
                  sx={{
                     fontFamily: 'Inter',
                     color: '#333',
                     fontWeight: '700',
                     fontSize: '14px',
                     lineHeight: '16.94px',
                  }}
               >
                  {title}
               </Typography>
               <Typography
                  sx={{
                     fontFamily: 'Inter',
                     color: '#333',
                     fontWeight: '400',
                     fontSize: '14px',
                     lineHeight: '16.94px',
                  }}
               >
                  {text}
               </Typography>
            </Box>
            <Box
               sx={{
                  backgroundColor: '#4D7BF1',
                  borderRadius: '100px',
                  width: 'fit-content',
                  padding: '9px 16px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  cursor: 'pointer',
               }}
            >
               <ReplyIcon style={{ transform: 'scaleX(-1)', color: '#fff', width: '22px' }} />

               <a href={CTA}>
                  <Typography
                     sx={{
                        fontFamily: 'Inter',
                        color: '#fff',
                        fontWeight: '700',
                        fontSize: '14px',
                        lineHeight: '18px',
                     }}
                  >
                     {buttonText}
                  </Typography>
               </a>
            </Box>
         </Box>
      </Box>
   );
}

function SingleProfileCause() {
   return (
      <Box sx={{ backgroundColor: '#fff', borderRadius: '4px', position: 'relative', aspectRatio: '113/86' }}>
         <Box
            sx={{
               position: 'absolute',
               top: 0,
               right: 0,
               width: '24px',
               height: '24px',
               transform: 'translateX(30%) translateY(-30%)',
            }}
         >
            <svg
               xmlns='http://www.w3.org/2000/svg'
               style={{ width: '100%', height: '100%' }}
               fill='none'
               viewBox='0 0 24 24'
            >
               <rect width='24' height='24' fill='#333' rx='12' />
               <path
                  fill='#fff'
                  d='M17.592 8.008a.833.833 0 00-1.184 0L10.2 14.225l-2.608-2.617a.852.852 0 00-1.184 1.225l3.2 3.2a.833.833 0 001.184 0l6.8-6.8a.834.834 0 000-1.225z'
               />
            </svg>
         </Box>
         <Box sx={{ p: '16px 12px' }}>
            <div>Bring them home</div>
         </Box>
      </Box>
   );
}

function PersonNetwork(props: any) {
   const { data: networkData } = props;

   const { title, data } = networkData;

   return (
      <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
         <Typography
            sx={{ fontFamily: 'Inter', color: '#333', fontWeight: '700', fontSize: '16px', lineHeight: '19.36px' }}
         >
            {title}
         </Typography>
         <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }}>
            {data?.map((singleData: any) => {
               const { icon, text, subHeader, order } = singleData;
               return (
                  <div key={subHeader} style={{ order }}>
                     <MediumBox icon={icon} number={text} text={subHeader} />
                  </div>
               );
            })}
         </Box>
      </Box>
   );
}

function PersonGiving(props: any) {
   const { data: givingData } = props;
   const { title, ctaTitle, ctaUrl, data } = givingData;
   return (
      <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
         <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
            <Typography
               sx={{ fontFamily: 'Inter', color: '#333', fontWeight: '700', fontSize: '16px', lineHeight: '19.36px' }}
            >
               {title}
            </Typography>
            <a href={ctaUrl}>
               <Typography
                  sx={{
                     color: '#4D7BF1',
                     fontFamily: 'Inter',
                     fontWeight: '600',
                     fontSize: '14px',
                     lineHeight: '16.94px',
                     cursor: 'pointer',
                  }}
               >
                  {ctaTitle}
               </Typography>
            </a>
         </Box>
         <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }}>
            {data?.map((singleData: any) => {
               const { icon, text, subHeader, order } = singleData;
               return (
                  <div key={subHeader} style={{ order }}>
                     <MediumBox icon={icon} number={text} text={subHeader} />
                  </div>
               );
            })}

            <MediumBox icon='home' number='10' text='Monthly giving streak' />
         </Box>
      </Box>
   );
}

function ImpactEquivalent(props: any) {
   return (
      <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
         <Typography sx={{ fontFamily: 'Inter', fontWeight: '700', fontSize: '14px', lineHeight: '16.94px' }}>
            The impact of Bono users over the past 12 months is equivalent to:
         </Typography>
         <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '6px' }}>
            <MediumBox icon='home' number='12' text='Homes built' />
            <MediumBox icon='home' number='1,600' text='Meal served' />
            <MediumBox icon='home' number='390' text='Kids helped' />
         </Box>
      </Box>
   );
}

function MediumBox(props: { icon: string; number: React.ReactNode; text: React.ReactNode }) {
   const { icon, number, text } = props;
   return (
      <Box sx={{ backgroundColor: '#fff', padding: '20px', borderRadius: '4px', height: '100%' }}>
         <DynamicIcon iconName={icon} />
         <Typography
            sx={{ fontFamily: 'Inter', color: '#333', fontWeight: '700', fontSize: '26px', lineHeight: '31.47px' }}
         >
            {number}
         </Typography>
         <Typography
            sx={{ fontFamily: 'Inter', color: '#999', fontSize: '12px', fontWeight: '600px', lineHeight: '14.52px' }}
         >
            {text}
         </Typography>
      </Box>
   );
}

function SmallBox(props: { selected: boolean; color: string; text: string }) {
   const { selected, color, text } = props;
   return (
      <Box
         sx={{
            backgroundColor: color || '#fff',
            borderRadius: '4px',
            position: 'relative',
            aspectRatio: '113/86',
            border: selected ? '2px solid rgba(0,0,0,0.3)' : 'none',
         }}
      >
         {selected && (
            <Box
               sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  width: '24px',
                  height: '24px',
                  transform: 'translateX(30%) translateY(-30%)',
               }}
            >
               <CheckCircleIcon />
            </Box>
         )}
         <Box sx={{ p: '16px 12px' }}>
            <Typography
               sx={{
                  color: selected ? '#333' : '#BBB',
                  fontFamily: 'Inter',
                  fontWeight: '700',
                  fontSize: '16px',
                  lineHeight: '18px',
               }}
            >
               {text}
            </Typography>
         </Box>
      </Box>
   );
}

const storeWd = {
   PersonMain,
   PersonFact,
   ProfileDescription,
   SingleProfileCause,
   PersonNetwork,
   PersonGiving,
   ImpactEquivalent,
};

export default storeWd;
