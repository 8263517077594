import React from 'react';
import { Box } from '@mui/material';
import './style.css';
import { motion } from 'framer-motion';
import { Img } from 'react-image';

interface CausesDetailsProps {
   title: string;
   desc: string;
   imageLink: string;
   removeCause?: () => void;
}

function CausesDetails({ title, desc, imageLink, removeCause }: CausesDetailsProps) {
   return (
      <Box
         sx={{
            mt: { xs: '1dvh', lg: '160px' },
            display: 'flex',
            alignItems: 'center',
            minHeight: { lg: '200px' },
            maxWidth: 'max-content',
         }}
      >
         <Box display={{ xs: 'none', lg: 'block' }}>
            <motion.div
               initial={{ height: 0 }}
               animate={{ height: 365 }}
               transition={{ duration: 0.3, ease: 'linear' }}
               style={{ width: '1px', backgroundColor: '#cccccc' }}
            />
         </Box>

         <motion.div
            initial={{ x: '230%', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: '230%' }}
            transition={{
               duration: 0.3,
               ease: 'linear',
               delay: 0.3,
            }}
            className='causes-details'
         >
            <Box sx={{ padding: { xs: '0px', lg: '0 30px' } }}>
               <p className='causes-details_title'>{title}</p>
               <Box
                  sx={{
                     display: 'flex',
                     flexDirection: { xs: 'row', lg: 'column' },
                     gap: '12px',
                     justifyContent: 'space-between',
                     width: '100%',
                  }}
               >
                  <div>
                     <p className='causes-details_desc'>{desc}</p>
                  </div>
                  <div>
                     <Img
                        className='causes-image'
                        src={imageLink}
                        alt={title}
                        loader={
                           <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='80px'
                              height='80px'
                              viewBox='0 0 180.119 139.794'
                           >
                              <g paintOrder='fill markers stroke' transform='translate(-13.59 -66.639)'>
                                 <path fill='#d0d0d0' d='M13.591 66.639H193.71v139.794H13.591z' />
                                 <path
                                    fill='#fff'
                                    d='M118.507 133.514l-34.249 34.249-15.968-15.968-41.938 41.937h152.374z'
                                    opacity='0.675'
                                 />
                                 <circle cx='58.217' cy='108.555' r='11.773' fill='#fff' opacity='0.675' />
                                 <path fill='none' d='M26.111 77.634h152.614v116.099H26.111z' />
                              </g>
                           </svg>
                        }
                     />
                  </div>
               </Box>
            </Box>
         </motion.div>
      </Box>
   );
}

export default CausesDetails;
