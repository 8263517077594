import * as React from 'react';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useSearchParams } from 'react-router-dom';
import { List, ListItem } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import ArrowForward from '@mui/icons-material/ArrowForward';

const drawerBleeding = 25;

interface Props {
   /**
    * Injected by the documentation to work in an iframe.
    * You won't need it on your project.
    */
   window?: () => Window;
}

const Root = styled('div')(({ theme }) => ({
   height: '100%',
   backgroundColor: theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled('div')(({ theme }) => ({
   backgroundColor: theme.palette.mode === 'light' ? '#F7F7F7' : grey[800],
}));

const Puller = styled('div')(({ theme }) => ({
   width: 60,
   height: 6,
   backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
   borderRadius: 3,
   position: 'absolute',
   top: 8,
   left: 'calc(50% - 30px)',
}));

export default function WelcomeDrawerMobile(props: Props) {
   const { window } = props;
   const { t } = useTranslation();
   const [searchParams, setSearchParams] = useSearchParams();
   const [open, setOpen] = React.useState((searchParams.get('welcome') && searchParams.get('welcome')) === 'true');

   const messages: string[] = t('common:gratitudePopUp.bulletsUp', { returnObjects: true });

   const toggleDrawer = (newOpen: boolean) => () => {
      setSearchParams({ welcome: 'false' });
      setOpen(newOpen);
   };

   // This is used only for the example
   const container = window !== undefined ? () => window().document.body : undefined;

   return (
      <Root>
         <CssBaseline />
         <Global
            styles={{
               '.MuiDrawer-root > .MuiPaper-root': {
                  height: `calc(58% - ${drawerBleeding}px)`,
                  overflow: 'visible',
               },
            }}
         />
         {open && (
            <SwipeableDrawer
               container={container}
               anchor='bottom'
               open={open}
               onClose={toggleDrawer(false)}
               onOpen={toggleDrawer(true)}
               swipeAreaWidth={drawerBleeding}
               disableSwipeToOpen={false}
               ModalProps={{
                  keepMounted: true,
                  BackdropProps: {
                     sx: {
                        backgroundColor: '#211B1B99',
                     },
                  },
                  sx: {
                     backdropFilter: 'none',
                     backgroundColor: 'rgba(0,0,0,0)',
                  },
               }}
            >
               <StyledBox
                  sx={{
                     position: 'absolute',
                     top: -drawerBleeding,
                     borderTopLeftRadius: 30,
                     borderTopRightRadius: 30,
                     visibility: 'visible',
                     right: 0,
                     left: 0,
                     height: '26px',
                  }}
               >
                  <Puller />
                  <Box />
               </StyledBox>
               <StyledBox
                  sx={{
                     px: 2,
                     pb: 2,
                     pt: 2.75,
                     height: '100%',
                     overflow: 'auto',
                  }}
               >
                  <Box
                     sx={{
                        width: { xs: '100%' },
                        background: '#F7F7F7',
                        px: 2.5,
                        position: 'relative',
                     }}
                  >
                     <Typography
                        sx={{
                           fontWeight: '900',
                           fontSize: '34px',
                           lineHeight: '38px',
                           color: '#333',
                        }}
                        variant='h5'
                     >
                        {t('common:gratitudePopUp.header')}
                     </Typography>
                     <Typography
                        sx={{
                           fontWeight: '700',
                           fontSize: '22px',
                           lineHeight: '26.63px',
                           color: '#333',
                           mt: 3.75,
                        }}
                        variant='h6'
                     >
                        {t('common:gratitudePopUp.subHeading')}
                     </Typography>
                     <List
                        sx={{
                           padding: '0px',
                        }}
                     >
                        {messages.map((text) => {
                           return (
                              <ListItem
                                 key={`message-${text}`}
                                 sx={{
                                    p: 0,
                                    py: 0.75,
                                 }}
                              >
                                 <CheckIcon
                                    sx={{
                                       color: '#0FBD00',
                                       strokeWidth: 2,
                                       stroke: 'currentColor',
                                       marginRight: '10px',
                                       width: '16px',
                                       height: '16px',
                                    }}
                                 />{' '}
                                 <Typography
                                    sx={{
                                       fontWeight: '500',
                                       fontSize: '16',
                                       color: '#333',
                                       lineHeight: '20px',
                                    }}
                                    variant='body1'
                                 >
                                    {text}
                                 </Typography>
                              </ListItem>
                           );
                        })}
                     </List>
                     <Typography
                        sx={{
                           fontWeight: '500',
                           fontSize: '16px',
                           color: '#333',
                           lineHeight: '20px',
                           mt: 3,
                        }}
                        variant='body1'
                     >
                        {t('common:gratitudePopUp.preFooter')}
                     </Typography>
                     <Button
                        variant='contained'
                        color='black'
                        onClick={toggleDrawer(false)}
                        sx={{
                           fontWeight: '700',
                           fontSize: '18px',
                           color: '#333',
                           lineHeight: '21.78px',
                           mt: 3.75,
                           textAlign: 'center',
                           width: '100%',
                           borderRadius: '100px',
                           py: 1.625,
                           textTransform: 'none',
                        }}
                     >
                        {t('common:gratitudePopUp.footer')}{' '}
                        <ArrowForward
                           sx={{
                              fontSize: '18px',
                              ml: 0.5,
                           }}
                        />
                     </Button>
                  </Box>
               </StyledBox>
            </SwipeableDrawer>
         )}
      </Root>
   );
}
