import { Box, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ReplyIcon from '@mui/icons-material/Reply';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getWidgetFromApiURL } from 'store/widget/widget.services';
import { LoadingIcon } from 'components/common';
import { causesState } from 'store/causes/causes.slice';
import { useAppSelector } from 'store/hooks';
import BonoLogo from '../../bono-logo/bonologo';

export function ImpactUpdates(props: {
   dataApiCall: string;
   slug: string | undefined;
   internalName: string;
   isPublic: boolean;
}) {
   const { dataApiCall, slug, isPublic, internalName } = props;
   const { t } = useTranslation();
   const [images, setImages] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const langPrefix = `${!isPublic ? 'private' : 'public'}.${internalName}`;

   const fetchData = async (url: string) => {
      try {
         const response = await getWidgetFromApiURL(url);
         setImages(
            response.data.posts.map((item: any) => {
               const cause = response.data.causes.find((c: any) => c.id === item.causeId);
               return {
                  ...item,
                  cause: cause?.title,
               };
            })
         );
      } catch (error) {
         console.error('Error fetching data:', error);
      } finally {
         setIsLoading(false);
      }
   };

   useEffect(() => {
      if (dataApiCall) {
         fetchData(dataApiCall);
      }
   }, [dataApiCall]);

   return (
      <Box
         sx={{
            // py: { md: '50px' },
            // px: { md: '20px', lg: '50px' },
            // backgroundColor: { md: '#fff' },
            // width: 'max-content',
            mb: { md: '10px' },
            mx: 'auto',
         }}
      >
         <Box sx={{ width: { xs: '100%' }, display: 'grid', gap: '10px' }}>
            <Typography
               sx={{
                  fontFamily: 'Inter',
                  fontWeight: '700',
                  fontSize: '24px',
                  lineHeight: '30px',
                  width: '100%',
               }}
            >
               {t(`${langPrefix}.heading`, { ns: 'widgets' })}
            </Typography>

            <motion.div
            // dragTransition={{ bounceStiffness: 20, bounceDamping: 20 }}
            // dragElastic={0.5}
            >
               <Box
                  sx={{
                     display: 'grid',
                     gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)' },
                     gap: { xs: '15px', md: '10px' },
                  }}
               >
                  {isLoading ? (
                     <Box
                        sx={{
                           width: '100%',
                           height: '100%',
                           minHeight: '200px',
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           background: '#E8E8E8',
                           borderRadius: '4px',
                        }}
                     >
                        <LoadingIcon />
                     </Box>
                  ) : (
                     <>
                        {images.length === 0 && (
                           <Box
                              sx={{
                                 width: '100%',
                                 height: '100%',
                                 minHeight: '100px',
                                 display: 'flex',
                                 justifyContent: 'start',
                                 alignItems: 'center',
                              }}
                           >
                              {t('common:noDataFoundError')}
                           </Box>
                        )}
                        {images.map((post: any, index) => {
                           return <Slide key={`post-${post.id.toString()}`} post={post} slug={slug} />;
                        })}
                     </>
                  )}
               </Box>
            </motion.div>
         </Box>
      </Box>
   );
}

export function Slide(props: { post: any; slug: string | undefined }) {
   const { post, slug } = props;
   const [showPopup, setShowPopup] = useState(false);
   const navigate = useNavigate();

   useEffect(() => {
      showPopup ? (document.body.style.overflowY = 'hidden') : (document.body.style.overflowY = 'scroll');
   }, [showPopup]);

   return (
      <>
         <AnimatePresence>
            {showPopup && (
               <motion.div
                  initial={{ x: '110%' }}
                  animate={{ x: 0 }}
                  exit={{ x: '110%' }}
                  transition={{ ease: 'linear' }}
                  style={{
                     position: 'fixed',
                     zIndex: 9999999999,
                     top: 0,
                     left: 0,
                  }}
               >
                  <Box
                     sx={{
                        width: '100vw',
                        height: '100vh',
                        overflow: 'scroll',
                        backgroundColor: '#fff',
                        pt: '70px',
                     }}
                  >
                     <Box
                        sx={{
                           width: '100%',
                           cursor: 'pointer',
                           display: 'flex',
                           justifyContent: 'space-between',
                           alignItems: 'center',
                           px: '20px',
                           height: '70px',
                           backgroundColor: '#333',
                           position: 'fixed',
                           left: 0,
                           top: 0,
                           zIndex: 20,
                        }}
                        onClick={() => setShowPopup(false)}
                     >
                        <BonoLogo />
                        <Box sx={{ color: '#fff', fontSize: '24px' }}>✖</Box>
                     </Box>
                     <Box sx={{ width: '100%', maxWidth: '400px', mx: 'auto', display: 'relative', zIndex: 10 }}>
                        <SlideBox post={post} showLearnMore={false} roundedCorners={false} />

                        <Box sx={{ px: '15px', py: '30px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                           <ImpactUpdateShortDescription text={post.tldr} />
                           <ImpactUpdateLongDescription text={post.body} />
                           <Box sx={{ pt: '15px' }}>
                              <ImpactBlueButton buttonText='Share this update' width='100%' />
                           </Box>
                        </Box>
                     </Box>
                  </Box>
               </motion.div>
            )}
         </AnimatePresence>
         <Box
            onClick={() => {
               navigate(`/impactDetail/${slug}/${post.id}`);
            }}
         >
            <SlideBox post={post} />
         </Box>
      </>
   );
}
export function SlideBox({
   post,
   showLearnMore = true,
   roundedCorners = true,
}: {
   post: any;
   showLearnMore?: boolean;
   roundedCorners?: boolean;
}) {
   const { causesList } = useAppSelector(causesState);
   return (
      <Box
         sx={{
            width: '100%',
            flexShrink: 0,
            aspectRatio: { xs: '360/190', md: '275/190' },
            borderRadius: roundedCorners ? { xs: '10px', md: '4px' } : '0px',
            overflow: 'hidden',
            position: 'relative',
            cursor: 'pointer',
         }}
      >
         <Box
            component='img'
            sx={{
               height: '100%',
               width: '100%',
               position: 'absolute',
               objectFit: 'cover',
               top: 0,
               left: 0,
            }}
            alt={post.title}
            src={post.displayImage}
         />
         <Box
            sx={{
               width: '100%',
               height: '100%',
               display: 'flex',
               position: 'relative',
               background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 50%);',
               padding: '20px',
               alignItems: 'flex-end',
            }}
         >
            <Box sx={{ display: 'grid', gap: '6px' }}>
               <Box
                  sx={{
                     backgroundColor: causesList.find((c) => c.id === post.causeId)?.impactBackground,
                     padding: '2px 6px',
                     borderRadius: '22px',
                     maxWidth: 'fit-content',
                  }}
               >
                  <Typography sx={{ fontFamily: 'Inter', fontWeight: '700', fontSize: '10px', lineHeight: '18px' }}>
                     {post.cause}
                  </Typography>
               </Box>
               <Typography
                  sx={{
                     color: '#fff',
                     fontFamily: 'Inter',
                     fontWeight: '700',
                     fontSize: '24px',
                     lineHeight: '28px',
                  }}
               >
                  {post.title}
               </Typography>

               {showLearnMore && <LearnMoreText />}
            </Box>
         </Box>
      </Box>
   );
}

function LearnMoreText({ color = '#fff' }: { color?: string }) {
   return (
      <Typography
         sx={{
            color,
            fontFamily: 'Inter',
            fontWeight: '600',
            fontSize: '12px',
            lineHeight: '14.52px',
            textDecoration: 'underline',
         }}
      >
         Learn more...
      </Typography>
   );
}

export function ImpactUpdateShortDescription({ text }: { text: string }) {
   return (
      <Typography sx={{ fontFamily: 'Inter', fontWeight: 700, fontSize: '16px', lineHeight: '19.36px' }}>
         {text}
      </Typography>
   );
}
export function ImpactUpdateLongDescription({ text }: { text: string }) {
   return (
      <Typography sx={{ fontFamily: 'Inter', fontWeight: 400, fontSize: '16px', lineHeight: '19.36px' }}>
         {text}
      </Typography>
   );
}

export function ImpactBlueButton({
   buttonText,
   link = '#',
   arrow = true,
   width = 'fit-content',
}: {
   buttonText?: string;
   link?: string;
   arrow?: boolean;
   width?: string;
}) {
   return (
      <Box style={{ textDecoration: 'none', cursor: 'pointer' }}>
         <Box
            sx={{
               backgroundColor: '#4D7BF1',
               borderRadius: '100px',
               width: { width },
               padding: '9px 16px',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               gap: '10px',
               cursor: 'pointer',
            }}
         >
            {arrow && <ReplyIcon style={{ transform: 'scaleX(-1)', color: '#fff', width: '22px' }} />}

            <Typography
               sx={{
                  fontFamily: 'Inter',
                  color: '#fff',
                  fontWeight: '700',
                  fontSize: '14px',
                  lineHeight: '18px',
               }}
            >
               {buttonText}
            </Typography>
         </Box>
      </Box>
   );
}
