import { configureStore, Action, ThunkAction, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { authSlice } from './auth/auth.slice';
import { causesSlice } from './causes/causes.slice';
import { commonSlice } from './common/common.slice';
import { widgetSlice } from './widget/widget.slice';

const persistConfig = {
   key: 'root',
   storage,
   whitelist: ['auth'],
};

const causesPersistConfig = {
   key: 'causes',
   storage,
   blacklist: ['selectedCausesList', 'causesList', 'convertedCausesList'],
};

const commonPersistConfig = {
   key: 'common',
   storage,
   whitelist: ['profileWasActivated', 'portfolioDoneBtn'],
};

const authPersistConfig = {
   key: 'auth',
   storage,
   whitelist: ['refreshToken', 'loggedUserData'],
};

const rootReducer = combineReducers({
   auth: persistReducer(authPersistConfig, authSlice.reducer),
   causes: persistReducer(causesPersistConfig, causesSlice.reducer),
   common: commonSlice.reducer,
   widget: widgetSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const bonoStore = configureStore({
   reducer: rootReducer,
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
         serializableCheck: false,
      }),
});

export const persistor = persistStore(bonoStore);

export default bonoStore;

export type RootState = ReturnType<typeof bonoStore.getState>;
export type AppDispatch = typeof bonoStore.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
