/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { routes } from 'routes/routes';


import {
   ButtonFurther,
   ContentWrapper,
   HaveLinkText,
   IconCaptionNumber,
   RegLinkText,
} from 'components/common';
import { Icon } from 'components/icon';
import { authState } from 'store/auth/auth.slice';
import { ENUM_ICON } from 'enums/icons';
import { Header } from 'components/header';
import { ButtonsBox, CenteredWrapper, PageMainLabel, PageSubLabel } from './styles';

export function WelcomePage() {
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const { t } = useTranslation();
   const [isButtonDisabled, setIsButtonDisabled] = useState(false);
   const [searchParams] = useSearchParams();
   const { loggedUserData } = useAppSelector(authState);
   const [returnUser, setReturnUser] = useState(searchParams.get('back') === 'true');


   useEffect(() => {
      document.title = t('siteTitleName');
   }, []);

   useEffect(() => {
      if(loggedUserData){
         
         if(loggedUserData.subscription?.status === 'active'){
            navigate(routes().myProfile);
         }else{
            setReturnUser(true);   
         }
      }
   }, [loggedUserData]);

   const handleNext = () => {
      if (loggedUserData) {
         navigate(`${routes().causes}${returnUser ? '?back=true' : ''}`);
      } else {
         navigate(routes().loginEmail);
      }
   };

   return (

      <Box sx={{
         flex: '1',
         display: 'flex',
         flexDirection: 'column',
         justifyContent: {xs: 'space-between', sm: 'start'},
         pb: 4,
         pl:{ sm: '30px' }, pr:{ sm: '30px' }
      }}>
         <Box component="div" display="flex" flexDirection="column" sx={{
            width: {
               xs: '100%',
               sm: '100%',
               textAlign: 'left',
            },
         }}>
            <Box width={{ xs: '100%', sm: '100%' }} sx={{ margin: 'auto', pt: { xs: '78px', sm: '0px' } }}>
               <PageMainLabel sx={{ paddingBottom: '10px' }}>
                  <Box component="span">
                     {returnUser ? t('common:homePage.headingBack') : t('homePage.heading')}
                  </Box>
               </PageMainLabel>
               <PageSubLabel sx={{ paddingBottom: ['25px', '30px', '34px'] }}>
                  {t('homePage.subHeading')}
               </PageSubLabel>
               <CenteredWrapper>
                  <IconCaptionNumber iconText="1">{t('common:bullets.1')}</IconCaptionNumber>
                  <IconCaptionNumber iconText="2">{t('common:bullets.2')}</IconCaptionNumber>
                  <IconCaptionNumber iconText="3">{t('common:bullets.3')}</IconCaptionNumber>
                  <IconCaptionNumber iconText="4">{t('common:bullets.4')}</IconCaptionNumber>
               </CenteredWrapper>
            </Box>
         </Box>
         <ButtonsBox width={{ xs: '100%' }} pt={{ xs: '15px', sm: '60px' }}>
            <ButtonFurther
               fullWidth
               // eslint-disable-next-line @typescript-eslint/no-empty-function
               onClick={isButtonDisabled ? () => {
               } : handleNext}
               endIcon={isButtonDisabled ? '' : <Icon name={ENUM_ICON.ARROW_RIGHT} />}
            >
               {isButtonDisabled ? 'Loading...' : t('common:letsStart')}
            </ButtonFurther>
            <RegLinkText sx={{ mt: { xs: '28px', sm: '28px' } }}>
               {t('common:clickTC')}
               <Link to={`${process.env.REACT_APP_MAIN_BASE_URL}/tc/`} target="_blank" ><span style={{ minWidth: 'max-content' }} >{t('common:terms')}</span></Link> and{' '}
               <Link to={`${process.env.REACT_APP_MAIN_BASE_URL}/privacy-policy/`} target="_blank" ><span style={{ minWidth: 'max-content' }}>{t('common:privacyPolicy')}</span></Link>
               <div>
                  <Trans
                     i18nKey="tcSupport"
                     // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label, react/self-closing-comp
                     components={{ mailLink: <a href="mailto:support@proisrael.co"></a> }}
                  />
               </div>
            </RegLinkText>
         </ButtonsBox>
      </Box>
   );
}
