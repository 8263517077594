/* eslint-disable react/destructuring-assignment */
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // Import useParams
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import ProfileLayout from 'components/profileLayout/profileLayout';
import { fetchWidgets, fetchWidgetsPublic, getWidgetFromApiURL } from 'store/widget/widget.services';
import { LoadingIcon } from 'components/common';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { authState, fetchUserByAccessToken, fetchUserBySlug } from 'store/auth/auth.slice';
import { routes } from 'routes/routes';
import { populateUrl } from 'utils/utils';
import WelcomeDrawer from 'components/welcomeDrawer/welcomeDrawer';
import WelcomeDrawerMobile from 'components/welcomeDrawer/welcomeDrawerMobile';
import { ShareButton, AboutBono } from '../../components/my-profile/widgets/common';
import BonoLogo from '../../components/bono-logo/bonologo';
import { DynamicIcon } from '../../components/dynamic-icon';
import { CausesSlide } from '../../components/my-profile/causes/causes-slide';

import { ImpactUpdates } from '../../components/my-profile/impact-updates';
import storeWd from '../../components/my-profile/widgets';

// Define types for display views data
interface WidgetFormat {
   text?: string;
   title?: string;
   showLogo?: boolean;
   popup?: boolean;
   buttonText?: string;
   CTA?: string;
   ctaUrl?: string;
   ctaTitle?: string;
}

export function MyProfilePage(): React.ReactElement {
   const { slug } = useParams(); // Get the slug from the URL
   const navigate = useNavigate();
   const { loggedUserData } = useAppSelector(authState);
   const dispatch = useAppDispatch();
   const [sortedData, setSortedData] = useState([]);
   const [userData, setUserData] = useState<any>({ firstName: '', lastName: '', publicName: '' });
   const sharelink = `${process.env.REACT_APP_BASE_URL}/p/${userData?.publicName}?ref_id=${userData?.settings?.referralCode}`;
   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down('md'));
   const { t } = useTranslation();

   async function fetchData() {
      try {
         let widgets;
         // check if the user is logged in and
         if (userData?.public && userData.id) {
            widgets = await fetchWidgetsPublic(userData.id);
         } else if (loggedUserData) {
            widgets = await fetchWidgets();
         }

         if (widgets) {
            const { displayViews } = widgets.data;
            if (isMobile) {
               displayViews.forEach((view: any) => {
                  const originalOrder = view.order;
                  view.order = view.mobileOrder;
                  view.mobileOrder = originalOrder;
               });
            }
            setSortedData(displayViews.sort((a: { order: number }, b: { order: number }) => a.order - b.order));
         }
      } catch (error) {
         console.error('Error fetching data:', error);
      }
   }

   useEffect(() => {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
         if (!loggedUserData) {
            dispatch(fetchUserByAccessToken());
         } else if (slug && loggedUserData?.publicName !== slug) {
            dispatch(fetchUserBySlug(slug))
               .then((response) => {
                  setUserData({ public: true, ...response.payload });
               })
               .catch((error) => {
                  console.log(error);
                  navigate(routes().welcome);
               });
         } else {
            setUserData(loggedUserData);
         }
      } else if (slug) {
         dispatch(fetchUserBySlug(slug))
            .then((response) => {
               if (response.meta.requestStatus === 'fulfilled') {
                  setUserData({ public: true, ...response.payload });
               } else {
                  // or page not found
                  navigate(routes().welcome);
               }
            })
            .catch((error) => {
               console.log(error);
               navigate(routes().welcome);
            });
      } else {
         // or Page not found
         navigate(routes().welcome);
      }
   }, [dispatch, loggedUserData]);

   useEffect(() => {
      if (userData?.publicName !== '' && sortedData.length < 1) {
         fetchData();
      }
      document.title = `${t('siteTitleName')}`;
   }, [userData]);

   useEffect(() => {
      if (sortedData.length > 0) {
         sortedData.forEach((view: any) => {
            const originalOrder = view.order;
            view.order = view.mobileOrder;
            view.mobileOrder = originalOrder;
         });
         setSortedData(sortedData.sort((a: { order: number }, b: { order: number }) => a.order - b.order));
      }
   }, [isMobile]);

   return (
      <ProfileLayout>
         <Box sx={{ display: 'flex' }} pt={{ md: 3 }} pb={{ md: 8 }}>
            <Box sx={{ flex: 1 }} pr={{ xs: 0, md: '29px' }}>
               <Box pt={{ xs: 2.5, md: 2 }} pb={{ xs: 2.5, md: 5 }}>
                  <storeWd.PersonMain
                     profilePhoto={userData?.details?.picture}
                     name={`${userData?.firstName} ${userData?.lastName ?? ''}`}
                     tagline={userData?.tagline}
                     memberDate={userData?.createdAt}
                  />
                  {isMobile ? <WelcomeDrawerMobile /> : createPortal(<WelcomeDrawer />, document.body)}
               </Box>
               {sortedData.map((section: any) => {
                  return (
                     <Box
                        sx={{
                           display: { xs: 'block', md: `${section.order % 2 === 1 ? 'block' : 'none'}` },
                           order: section.order,
                           boxSizing: { xs: 'border-box' },
                        }}
                        key={`section-${section.id}`}
                     >
                        {section.active && (
                           <>
                              {section.type === 'displayData' && <DisplayDataWidget data={section} />}
                              {section.type === 'causes' && (
                                 <Box sx={{ order: 10 }}>
                                    <CausesWidget data={section} isPublic={userData.public} />
                                 </Box>
                              )}
                              {section.type === 'causesOther' && (
                                 <Box sx={{ order: 10 }}>
                                    <CausesOther data={section} />
                                 </Box>
                              )}
                              {section.type === 'banner' && (
                                 <Box>
                                    <BannerDisplayData data={section} userData={userData} />
                                 </Box>
                              )}
                              {section.type === 'Impact' && (
                                 <ImpactUpdates
                                    dataApiCall={section.dataApiCall}
                                    internalName={section.internalName}
                                    slug={slug}
                                    isPublic={userData.public}
                                 />
                              )}
                           </>
                        )}
                     </Box>
                  );
               })}
               <Box
                  sx={{
                     paddingY: {
                        xs: 4,
                     },
                     display: { xs: 'block', md: 'none' },
                     order: 10,
                  }}
               >
                  <AboutBono shareLink={sharelink} isPublic={userData.public} />
               </Box>
            </Box>
            <Box
               sx={{
                  borderLeft: { xs: 'none', md: '1px solid rgba(51, 51, 51, .3)' },
                  width: { xs: '0px', md: '330px', lg: '370px' },
               }}
               pl={{ xs: 0, md: '29px' }}
               pt={{ xs: 0, md: '6px' }}
            >
               <Stack direction='column' spacing={0}>
                  {sortedData.map((section: any) => {
                     return (
                        <Box
                           sx={{
                              display: { xs: 'none', md: `${section.order % 2 === 0 ? 'block' : 'none'}` },
                              order: section.order,
                           }}
                           mb={1}
                           key={`section-${section.id}`}
                        >
                           {section.active && (
                              <>
                                 {section.type === 'displayData' && <DisplayDataWidget data={section} />}
                                 {section.type === 'causes' && (
                                    <Box sx={{ order: 10 }}>
                                       <CausesWidget data={section} isPublic={userData.public} />
                                    </Box>
                                 )}
                                 {section.type === 'causesOther' && (
                                    <Box sx={{ order: 10 }}>
                                       <CausesOther data={section} />
                                    </Box>
                                 )}
                                 {section.type === 'banner' && (
                                    <Box>
                                       <BannerDisplayData data={section} userData={userData} />
                                    </Box>
                                 )}
                                 {section.type === 'Impact' && (
                                    <ImpactUpdates
                                       dataApiCall={section.dataApiCall}
                                       internalName={section.internalName}
                                       slug={slug}
                                       isPublic={userData.public}
                                    />
                                 )}
                              </>
                           )}
                        </Box>
                     );
                  })}
                  <Box
                     sx={{
                        display: { xs: 'none', md: 'block' },
                        order: 10,
                     }}
                  >
                     <AboutBono shareLink={sharelink} isPublic={userData.public} />
                  </Box>
               </Stack>
            </Box>
         </Box>
         {/* <div className='profile-link'>

         </div>
         <div className='profile-network'>a</div>
         <div className='profile-giving'>
            <CausesWidget />
         </div>
         <div className='profile-impact'>a</div>
         <div className='profile-impact-past'>a</div>
         <div className='profile-impact-updates'>a</div>
         */}
      </ProfileLayout>
   );
}

function DisplayDataWidget(props: any) {
   const [data, setData] = useState<any | null>(null);
   const [isLoading, setIsLoading] = useState(true);

   const {
      data: { widgetFormat, dataApiCall } = {},
   }: {
      data?: {
         widgetFormat?: WidgetFormat;
         dataApiCall?: string;
      };
   } = props || {};
   // eslint-disable-next-line react/destructuring-assignment
   const internalData = props.data;

   const { title, buttonText, CTA, showLogo } = widgetFormat || {};

   const fetchData = async (url: string) => {
      try {
         const response = await getWidgetFromApiURL(url);
         setData(Array.isArray(response.data) ? response.data : props?.data?.data);
      } catch (error) {
         setData(props?.data?.data);
         console.error('Error fetching data:', error);
      } finally {
         setIsLoading(false);
      }
   };

   useEffect(() => {
      if (dataApiCall) {
         fetchData(dataApiCall);
      }
   }, [dataApiCall]);

   const langPrefix = `${internalData.private ? 'private' : 'public'}.${internalData.internalName}`;
   const { t, i18n } = useTranslation();

   return (
      <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column', mb: '30px' }}>
         {(title || buttonText) && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '30px' }}>
               <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', gap: '30px' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                     <Typography
                        sx={{
                           fontFamily: 'Inter',
                           color: '#333',
                           fontWeight: '700',
                           fontSize: '16px',
                           lineHeight: '19.36px',
                        }}
                     >
                        {t(`${langPrefix}.title`, { ns: 'widgets' })}
                     </Typography>
                     {i18n.exists(`${langPrefix}.subTitle`, { ns: 'widgets' }) ? (
                        <Typography
                           sx={{
                              fontFamily: 'Inter',
                              color: '#333',
                              fontWeight: '400',
                              fontSize: '14px',
                              lineHeight: '16px',
                           }}
                        >
                           {t(`${langPrefix}.subTitle`, { ns: 'widgets' })}
                        </Typography>
                     ) : null}
                  </Box>

                  {buttonText && (
                     <a href={CTA}>
                        <Typography
                           sx={{
                              color: '#4D7BF1',
                              fontFamily: 'Inter',
                              fontWeight: '600',
                              fontSize: '14px',
                              lineHeight: '16.94px',
                              cursor: 'pointer',
                           }}
                        >
                           {buttonText}
                        </Typography>
                     </a>
                  )}
               </Box>
               {showLogo && <BonoLogo width='53px' />}
            </Box>
         )}
         {isLoading ? (
            <Box
               sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  minHeight: '120px',
                  alignItems: 'center',
                  background: '#E8E8E8',
                  borderRadius: '4px',
               }}
            >
               <LoadingIcon />
            </Box>
         ) : (
            <Box
               sx={{ display: 'grid', gridTemplateColumns: `repeat(${Math.min(data?.length, 3)}, 1fr)`, gap: '10px' }}
            >
               {Array.isArray(data) &&
                  data?.map((singleData: any) => {
                     const { icon, txtData, subHeader, order } = singleData;
                     return (
                        <div key={subHeader} style={{ order }}>
                           {/* TODO give new type and divide value by 100 */}
                           <MediumBox icon={icon} number={txtData} text={subHeader} />
                        </div>
                     );
                  })}
            </Box>
         )}
      </Box>
   );
}
function BannerDisplayData(props: any) {
   const {
      data: { background, data = [], widgetFormat: { text, title, showLogo, popup, ctaUrl, buttonText } = {} } = {},
      userData,
   }: {
      data: {
         background?: string;
         data?: any[];
         widgetFormat?: WidgetFormat;
         active?: boolean;
      };
      userData: any;
   } = props || {};

   const internalData = props.data;
   const langPrefix = `${internalData.private ? 'private' : 'public'}.${internalData.internalName}`;
   const { t, i18n } = useTranslation();

   let link = '#';
   if (ctaUrl) {
      link = populateUrl(
         ctaUrl,
         process.env.REACT_APP_BASE_URL,
         userData?.publicName,
         userData?.settings?.referralCode
      );
   }
   const ctaTest = t(`${langPrefix}.ctaText`, { ns: 'widgets' });

   return (
      <Box sx={{ display: 'flex', fontFamily: 'Inter', mb: '30px' }}>
         <Box sx={{ backgroundColor: '#333', width: '3px', display: { xs: 'grid', md: 'none' }, flexShrink: 0 }} />
         <Box sx={{ flexGrow: 1, padding: { xs: '0 0 0 16px', md: '0' }, display: 'grid', gap: '15px' }}>
            <Box>
               <Typography
                  sx={{
                     fontFamily: 'Inter',
                     color: '#333',
                     fontWeight: '700',
                     fontSize: '14px',
                     lineHeight: '16.94px',
                  }}
               >
                  {t(`${langPrefix}.title`, { ns: 'widgets' })}
               </Typography>
               <Typography
                  sx={{
                     fontFamily: 'Inter',
                     color: '#333',
                     fontWeight: '400',
                     fontSize: '14px',
                     lineHeight: '16.94px',
                  }}
               >
                  {t(`${langPrefix}.text`, { ns: 'widgets' })}
               </Typography>
            </Box>
            <ShareButton CTALink={link} buttonText={ctaTest} showPopup={popup} />
         </Box>
      </Box>
   );
}

function CausesOther(props: any) {
   const [causes, setCauses] = useState<any | null>(null);

   const {
      data: { id, type, order, background, dataApiCall, widgetFormat, active } = {},
   }: {
      data?: {
         id?: number;
         type?: string;
         order?: number;
         background?: string;
         dataApiCall?: '';
         widgetFormat?: WidgetFormat;
         active?: boolean;
      };
   } = props || {};
   const { title }: WidgetFormat = widgetFormat || {};
   const [isLoading, setIsLoading] = useState(false);
   const internalData = props.data;
   const langPrefix = `${internalData.private ? 'private' : 'public'}.${internalData.internalName}`;
   const { t, i18n } = useTranslation();

   const fetchData = async () => {
      setIsLoading(true);
      try {
         if (dataApiCall) {
            const userCausesResponse = await getWidgetFromApiURL(dataApiCall);
            setCauses(userCausesResponse.data.data);
         }
      } catch (error) {
         console.error('Error fetching data:', error);
      } finally {
         setIsLoading(false);
      }
   };

   useEffect(() => {
      fetchData();
   }, []);

   return (
      <Box
         sx={{
            display: 'flex',
            gap: '20px',
            flexDirection: 'column',
            mb: '30px',
            overflow: { sx: 'hidden', sm: 'none' },
         }}
      >
         <Box sx={{ display: 'flex', gap: '16px', justifyContent: 'space-between' }}>
            <Typography
               sx={{ fontFamily: 'Inter', color: '#333', fontWeight: '700', fontSize: '16px', lineHeight: '19.36px' }}
            >
               {t(`${langPrefix}.title`, { ns: 'widgets' })}
            </Typography>
         </Box>
         {isLoading && !causes ? (
            <Box
               sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  minHeight: '400px',
                  alignItems: 'center',
                  background: '#E8E8E8',
                  borderRadius: '4px',
               }}
            >
               <LoadingIcon />
            </Box>
         ) : (
            <Box
               sx={{
                  display: 'grid',
                  gridTemplateColumns: '113px 113px 113px',
                  gap: '10px',
               }}
            >
               {causes?.map((item: any) => {
                  return (
                     <Box
                        sx={{
                           width: '100%',
                           height: '100px',
                           background: 'white',
                           border: '2px solid #33333366',
                           borderRadius: '4px',
                           boxSizing: 'border-box',
                           padding: '16px 12px',
                           textAlign: 'start',
                           color: '#9A9A9A',
                           fontWeight: '700',
                           fontSize: '16px',
                           lineHeight: '18px',
                           textOverflow: 'ellipsis',
                           wordWrap: 'break-word',
                           overflow: 'hidden',
                        }}
                        key={item.id}
                     >
                        {item.title}
                     </Box>
                  );
               })}
            </Box>
         )}
      </Box>
   );
}

function CausesWidget(props: any) {
   const [causes, setCauses] = useState<any | null>(null);
   const internalData = props.data;
   const langPrefix = `${internalData.private ? 'private' : 'public'}.${internalData.internalName}`;
   const { t, i18n } = useTranslation();

   const {
      data: { id, type, order, background, dataApiCall, widgetFormat, active } = {},
      isPublic = false,
   }: {
      data?: {
         id?: number;
         type?: string;
         order?: number;
         background?: string;
         dataApiCall?: '';
         widgetFormat?: WidgetFormat;
         active?: boolean;
      };
      isPublic: boolean;
   } = props || {};
   const { title, ctaUrl, ctaTitle, showLogo }: WidgetFormat = widgetFormat || {};
   const [isLoading, setIsLoading] = useState(false);

   const fetchData = async () => {
      setIsLoading(true);
      try {
         if (dataApiCall) {
            const userCausesResponse = await getWidgetFromApiURL(dataApiCall);
            setCauses(userCausesResponse.data);
         }
      } catch (error) {
         console.error('Error fetching data:', error);
      } finally {
         setIsLoading(false);
      }
   };

   useEffect(() => {
      fetchData();
   }, []);

   return (
      <Box
         sx={{
            display: 'flex',
            gap: '10px',
            flexDirection: 'column',
            mb: '30px',
            overflow: { sx: 'hidden', sm: 'none' },
         }}
      >
         <Box sx={{ display: 'flex', gap: '16px', justifyContent: 'space-between' }}>
            <Typography
               sx={{ fontFamily: 'Inter', color: '#333', fontWeight: '700', fontSize: '16px', lineHeight: '19.36px' }}
            >
               {t(`${langPrefix}.title`, { ns: 'widgets' })}
            </Typography>
            {ctaUrl && (
               <a href={ctaUrl}>
                  <Typography
                     sx={{
                        color: '#4D7BF1',
                        fontFamily: 'Inter',
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '16.94px',
                        cursor: 'pointer',
                     }}
                  >
                     {t(`${langPrefix}.ctaTitle`, { ns: 'widgets' })}
                  </Typography>
               </a>
            )}
         </Box>
         {isLoading ? (
            <Box
               sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  minHeight: '400px',
                  alignItems: 'center',
                  background: '#E8E8E8',
                  borderRadius: '4px',
               }}
            >
               <LoadingIcon />
            </Box>
         ) : (
            <CausesSlide data={causes ?? []} isPublic={isPublic} />
         )}
      </Box>
   );
}

function MediumBox(props: { icon: string; number: React.ReactNode; text: React.ReactNode }) {
   const { icon, number, text } = props;
   return (
      <Box sx={{ backgroundColor: '#fff', padding: '10px', borderRadius: '4px', height: '100%', lineHeight: 1 }}>
         <DynamicIcon iconName={icon} />
         <Typography
            sx={{ fontFamily: 'Inter', color: '#333', fontWeight: '700', fontSize: '26px', lineHeight: '30px' }}
         >
            {number}
         </Typography>
         <Typography
            sx={{ fontFamily: 'Inter', color: '#999', fontSize: '12px', fontWeight: '500', lineHeight: '14.52px' }}
         >
            {text}
         </Typography>
      </Box>
   );
}
