import api from 'api';

import { CauseTypes, CharityTypes } from 'store/causes/types';
import type { SignupDataTypes } from './types';

const API_DOMAIN = process.env.REACT_APP_BASE_URL;

export const getStripePayment = (money: number, serviceFee?: number) => {
   return api.post('/v1/payment/stripe/subscriptions', {
      amount: money * 100,
      serviceFee: serviceFee ? serviceFee * 100 : 0,
      successUrl: `${API_DOMAIN}/gratitude?session_id={CHECKOUT_SESSION_ID}`,
      cancelUrl: `${API_DOMAIN}/subscription`,
   });
};

export const getStripeFromProfile = () => {
   return api.get('/v1/payment/stripe/portal');
};

export const registerUser = (data: SignupDataTypes) => {
   return api.post('/v1/auth/register', data);
};

export const registerUserAnonymous = (data: SignupDataTypes, causes: CauseTypes[], charities: CharityTypes[]) => {
   return api.post('/v1/auth/register/anonymous', {
      ...data,
      causes: causes.map((c) => c.id),
      charities: charities.map((c) => c.id),
   });
};

export const registerUserAnonymousUpdate = (data: SignupDataTypes) => {
   return api.patch('/v1/users/update/anonymous', data);
};

export const loginWithGoogle = (googleToken: string) => {
   return api.put('/v1/auth/login/google', { token: googleToken });
};

export const loginWithGoogleAnonymous = (
   googleToken: string,
   data?: SignupDataTypes,
   causes?: CauseTypes[],
   charities?: CharityTypes[]
) => {
   const userData = {
      ...data,
      causes: causes?.map((c) => c.id),
      charities: charities?.map((c) => c.id),
   };

   return api.put('/v1/auth/login/google', { token: googleToken, ...userData });
};

export const loginUser = (data: { email: string; password: string }) => {
   return api.post('/v1/auth/login', data);
};

export const getUserByAccessToken = () => {
   return api.get(`/v1/users/me`);
};

export const getUserBySlug = (slug: string) => {
   return api.get(`/v1/publicProfile/slug/${slug}`);
};

export const getVerificationCode = (email: string) => {
   return api.get(`/v1/auth/verify/password.forgot/email=${email}`);
};

export const updatePasswordWithCode = (userData: { email: string; password: string; code: string }) => {
   return api.put(`/v1/auth/passwords/forgot`, userData);
};

// TODO change return url
export const signInWithMagicLink = (data: { email: string }) => {
   return api.post(`/v1/auth/login/magicLink`, { ...data, returnUrl: `${process.env.REACT_APP_BASE_URL}/ml` });
};

export const validateMagicLinkCode = (code: string) => {
   return api.get(`/v1/auth/login/magicLink/verify/${code}`);
};

export const sendVerificationCodeMagicLink = (email: string, type: string) => {
   return api.get(`/v1/auth/verify/${type}/email=${email}`);
};
