import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import Intercom from '@intercom/messenger-js-sdk';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { AppRoutes } from 'routes/routes-data';
import { getCausesList, causesState } from 'store/causes/causes.slice';
import { addRefCode } from 'store/auth/auth.slice';
import { ProtectedRoute } from 'components/protected/protected-route';
import { AnonymousRoute } from 'components/anonymous/anonymous-route';
import { Header } from './components/header';

function App() {
   const location = useLocation();
   const dispatch = useAppDispatch();

   const { causesList } = useAppSelector(causesState);

   useEffect(() => {
      if (causesList.length === 0) {
         dispatch(getCausesList());
      }
   }, [dispatch]);

   useEffect(() => {
      if (location.search) {
         const searchParams = new URLSearchParams(location.search);
         const referalCode = searchParams.get('ref_id');

         if (referalCode) {
            dispatch(addRefCode(referalCode));
         }
      }
   }, [location.search, dispatch]);

   // document.body.style.background = '#F7F7F7';
   /*
   useEffect(() => {
      if (location.pathname === routes().welcome) {
         document.body.style.background =
            'linear-gradient(168deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%), #4777F1';
      } else {
         document.body.style.background = '#F7F7F7';
      }

      return () => {
         document.body.style.background = '';
      };
   }, [location.pathname]); */

   useEffect(() => {
      window.analytics?.page();
   }, [location.pathname, location.search]);

   if (process.env.REACT_APP_INTERCOM) {
      Intercom({
         app_id: process.env.REACT_APP_INTERCOM,
      });
   }

   return (
      <>
         <ScrollToTop />
         <AppRoutes />
      </>
   );
}

export default App;

function ScrollToTop() {
   const { pathname } = useLocation();
   useEffect(() => {
      window.scrollTo(0, 0);
   }, [pathname]);

   return null;
}
