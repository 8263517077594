import type { AuthSliceTypes } from './types';

export const initialState: AuthSliceTypes = {
   signupData: {
      email: '',
      password: '',
      firstName: 'Random name',
      details: {
         gender: null,
      },
      codeFromEmail: '',
   },
   signupDataStatus: null,
   signupDataError: null,
   signupDataLoading: false,

   loggedUserData: null,
   accessToken: null,

   fetchVerCodeStatus: null,
   fetchVerCodeError: null,
   fetchVerCodeLoader: false,

   sendNewUserWithCodeStatus: null,
   sendNewUserWithCodeError: null,
   sendNewUserWithCodeLoader: false,

   loggedUser: null,
   loggedUserStatus: null,
   loggedUserError: null,
   loggedUserLoader: false,

   saveNewUserPasswordStatus: null,
   saveNewUserPasswordError: null,
   saveNewUserPasswordLoader: false,
};
