import { createTheme } from '@mui/material';
import type { TypographyCustomOptions } from './types';

const fontFamily = ['Inter', 'Helvetica', 'Arial', 'sans-serif'].join(',');

const typography: TypographyCustomOptions = {
   fontSize: 16,
   fontFamily,
   htmlFontSize: 16,
   fontWeightThin: 100,
   fontWeightExtraLight: 200,
   fontWeightLight: 300,
   fontWeightRegular: 400,
   fontWeightMedium: 700,
   fontWeightBold: 900,
   pxToRem: (size: number) => `${size / 16}rem`,
};

const customOptions = {
   size: {
      font_4: 4,
      font_6: 6,
      font_8: 8,
      font_10: 10,
      font_11: 11,
      font_12: 12,
      font_14: 14,
      font_16: 16,
      font_18: 18,
      font_20: 20,
      font_22: 22,
      font_24: 24,
      font_25: 25,
      font_26: 26,
      font_28: 28,
      font_30: 30,
      font_32: 32,
      font_34: 34,
      font_36: 36,
      font_40: 40,
      font_48: 48,
      font_50: 50,
      font_52: 52,
      font_54: 54,
      font_64: 64,
   },
   rootSpacing: {
      paddingRight: '1.875rem',
      paddingLeft: '1.875rem',
   },
};

export const bonoTheme = createTheme({
   components: {
      MuiCssBaseline: {
         styleOverrides: `
          html {
            font-size: ${typography.pxToRem(customOptions.size.font_16)};
          }
          a {
            text-transform: capitalize;
          }
        `,
      },
      MuiContainer: {
         styleOverrides: {
            root: ({ theme }) => ({
               paddingLeft: theme.rootSpacing.paddingRight,
               paddingRight: theme.rootSpacing.paddingLeft,
            }),
         },
      },
      MuiTypography: {
         styleOverrides: {
            root: ({ theme }) => ({
               color: theme.palette.black.main,
               '&.SubscriptionAmount': {
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  '& > p': {
                     position: 'relative',
                  },
                  '& > span': {
                     fontSize: '50px',
                     position: 'relative',
                     top: '-2px',
                  },
               },
               '&.ProfileUserName': {
                  fontSize: theme.typography.pxToRem(theme.size.font_22),
                  fontWeight: theme.typography.fontWeightBold,
                  textTransform: 'uppercase',
               },
               '&.ThanksAnImpact': {
                  fontSize: theme.typography.pxToRem(theme.size.font_16),
                  fontWeight: theme.typography.fontWeightMedium,
               },
               '&.ProfileNaming': {
                  fontSize: theme.typography.pxToRem(theme.size.font_26),
                  fontWeight: theme.typography.fontWeightBold,
                  letterSpacing: '0.52px',
                  display: 'flex',
                  flexDirection: 'column',
                  '& > span': {
                     fontSize: theme.typography.pxToRem(theme.size.font_26),
                     fontWeight: theme.typography.fontWeightBold,
                     letterSpacing: '0.52px',
                  },
               },
               '&.ProfileUserSince': {
                  fontSize: theme.typography.pxToRem(theme.size.font_12),
                  color: theme.palette.gray.additional,
               },
               '&.ModalTitle': {
                  fontSize: theme.typography.pxToRem(theme.size.font_24),
                  letterSpacing: '0.48px',
                  fontWeight: theme.typography.fontWeightBold,
                  lineHeight: '40px',
               },
               '&.ModalDescription': {
                  fontSize: theme.typography.pxToRem(theme.size.font_18),
                  fontWeight: theme.typography.fontWeightRegular,
               },
            }),
         },
      },
      MuiAvatar: {
         styleOverrides: {
            root: ({ theme }) => ({
               '&.ProfileAvatar': {
                  width: theme.typography.pxToRem(58),
                  height: theme.typography.pxToRem(58),
               },
            }),
         },
      },
      MuiDivider: {
         styleOverrides: {
            root: ({ theme }) => ({
               '&.ModalDivider': {
                  borderWidth: '1px',
                  borderColor: `${theme.palette.black.main}`,
               },
            }),
         },
      },
      MuiButton: {
         defaultProps: {
            disableRipple: true,
         },
         styleOverrides: {
            root: ({ theme }) => ({
               '&.ForgotPassword': {
                  letterSpacing: '0.28px',
                  fontSize: theme.size.font_14,
                  fontWeight: theme.typography.fontWeightBold,
                  color: theme.palette.black.main,
               },
            }),
         },
      },
      MuiTextField: {
         styleOverrides: {
            root: ({ ownerState, theme }) => ({
               '& .MuiOutlinedInput-root': {
                  border: '2px solid',
                  borderColor: ownerState.error ? theme.palette.red.main : theme.palette.black.main,
                  borderRadius: theme.typography.pxToRem(theme.size.font_8),
                  minHeight: theme.typography.pxToRem(48),
                  height: theme.typography.pxToRem(48),
               },
               '& .MuiFormHelperText-root': {
                  margin: '0 auto',
                  fontSize: theme.typography.pxToRem(theme.size.font_14),
               },
               '& .MuiFormHelperText-root.Mui-error': {
                  color: theme.palette.red.main,
               },
               '&.CommonInput': {
                  '& input': {
                     color: theme.palette.gray.additional,
                     padding: `${theme.typography.pxToRem(theme.size.font_10)} ${theme.typography.pxToRem(
                        theme.size.font_8
                     )}`,
                     fontSize: theme.typography.pxToRem(theme.size.font_16),
                     fontWeight: theme.typography.fontWeightRegular,
                  },
                  '&:hover': {
                     backgroundColor: 'transparent',
                  },
                  '& fieldset': {
                     border: 'none !important',
                  },
                  '&:hover fieldset': {
                     borderColor: theme.palette.black.main,
                  },
               },
            }),
         },
      },
      MuiIconButton: {
         defaultProps: {
            disableRipple: false,
         },
      },
      MuiLink: {
         styleOverrides: {
            root: ({ theme }) => ({
               color: theme.palette.black.main,
               textDecorationColor: theme.palette.black.main,
            }),
         },
      },
      MuiFab: {
         styleOverrides: {
            root: {
               '&.MuiFab-black': {
                  backgroundColor: '#222222',
                  color: '#fff',
               },
            },
         },
      },
      MuiButtonBase: {
         styleOverrides: {
            root: {
               '&.MuiButton-containedBlack': {
                  backgroundColor: '#222222',
                  color: '#fff',
               },
            },
         },
      },
   },
   palette: {
      white: {
         main: '#fff',
      },
      black: {
         main: '#333',
      },
      gray: {
         main: '#D9D9D9',
         additional: '#545454',
         third: '#71727A',
         light: '#9194A6',
         extraLight: '#ccc',
         inputText: '#7E7C78',
      },
      red: {
         main: '#FF5656',
      },
      gradient: {
         primaryColor: 'linear-gradient(168deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%), #4777F1',
      },
      main: {
         mainColor: '#4777F1',
         pageBackground: '#F7F7F7',
      },
   },
   typography,
   ...customOptions,
});

declare module '@mui/material/Fab' {
   interface FabPropsColorOverrides {
      black: true;
   }
}

declare module '@mui/material/Button' {
   interface ButtonPropsColorOverrides {
      black: true;
   }
}
