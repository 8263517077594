import { RouteObject, useRoutes } from 'react-router-dom';
import React from 'react';
import {
   WelcomePage,
   EmailPage,
   PasswordPage,
   GenderPage,
   CausesPage,
   SubscriptionPage,
   PortfolioPage,
   CharityPage,
   NamePage,
   GeneratingProfilePage,
   LoginEmailPage,
   LoginPasswordPage,
   ProfilePage,
   NewPasswordPage,
   ResetPasswordPage,
   IntroPage,
   AnonymousConnectPage,
   LoadingProfilePage,
   ForgotPasswordPage,
   MyProfilePage,
   ImpactDetailPage,
   MyProfileForward,
} from 'pages';
import { NoLayout, PublicLayout } from 'components/layout/publicLayout';
import { SignUpPage } from 'pages/signUp/signUp';
import { CheckYourEmail } from 'pages/checkYourEmail/checkYourEmail';
import { SignInPage } from 'pages/signIn/signIn';
import { GratitudeDisplayPage } from 'pages/gratitude/gratitude.display.page';
import { GratitudePage } from 'pages/gratitude/gratitude.page';

import { MagicLinkVerification } from 'pages/magicLinkVerification/magicLinkVerification.page';
import { routes } from './routes';

export const AppRoutes: React.FC = () => {
   const routeList: RouteObject[] = [
      {
         element: <PublicLayout />,
         children: [
            {
               path: routes().welcome,
               Component: WelcomePage,
               id: '1',
            },
            {
               path: routes().loginEmail,
               Component: SignUpPage,
               id: '2',
            },
            {
               path: routes().causes,
               Component: CausesPage,
               id: '3',
            },
            {
               path: routes().checkYourEmail,
               Component: CheckYourEmail,
               id: '4',
            },
            {
               path: routes().signIn,
               Component: SignInPage,
               id: '5',
            },
            {
               path: routes().generatePage,
               Component: GeneratingProfilePage,
               id: '6',
            },
            {
               path: routes().portfolio,
               Component: PortfolioPage,
               id: '7',
            },
            {
               path: routes().subscription,
               Component: SubscriptionPage,
               id: '8',
            },
            {
               path: routes().share,
               Component: GratitudeDisplayPage,
               id: '9',
            },
            {
               path: routes().magicLink,
               Component: MagicLinkVerification,
               id: '10',
            },
         ],
      },
      {
         element: <NoLayout />,
         children: [
            {
               path: routes().gratitude,
               Component: GratitudePage,
               id: '17',
            },
            {
               path: routes().charity,
               Component: CharityPage,
               id: '11',
            },
            {
               path: routes().name,
               Component: NamePage,
               id: '12',
            },
            {
               path: routes().profile,
               Component: ProfilePage,
               id: '13',
            },
            {
               path: routes().newPassword,
               Component: NewPasswordPage,
               id: '14',
            },
            {
               path: routes().resetPassword,
               Component: ResetPasswordPage,
               id: '16',
            },
            {
               path: routes().intro,
               Component: IntroPage,
               id: '18',
            },
            {
               path: routes().anonymousConnect,
               Component: AnonymousConnectPage,
               id: '19',
            },
            {
               path: routes().loadingProfile,
               Component: LoadingProfilePage,
               id: '20',
            },
            {
               path: routes().forgotPassword,
               Component: ForgotPasswordPage,
               id: '21',
            },
            {
               path: routes().myProfile,
               Component: MyProfileForward,
               id: '22',
            },
            {
               path: routes().impactDetail,
               Component: ImpactDetailPage,
               id: '23',
            },
            {
               path: routes().publicProfile,
               Component: MyProfilePage,
               id: '24',
            },
         ],
      },
   ];

   return useRoutes(routeList);
};
