import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { routes } from 'routes/routes';
import { PageWrapper } from 'wrappers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Icon } from 'components/icon';
import { ENUM_ICON } from 'enums/icons';
import { addCodeFromEmail, addEmail, authState, fetchVerificationCode } from 'store/auth/auth.slice';
import { BottomContentBox, ButtonFurther, ContentWrapper, PageMainLabel, PageSubLabel } from 'components/common';

export function ForgotPasswordPage() {
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const inputRef = useRef<HTMLDivElement | null>(null);
   const sessionProfileActivated = sessionStorage.getItem('profileWasActivated');
   const [email, setEmail] = useState('');
   const [alert, setAlert] = useState(false);
   const { t } = useTranslation();
   const [isFocused, setIsFocused] = useState(false);
   const [code, setCode] = useState('');
   const [error, setError] = useState(false);

   const { fetchVerCodeError } = useAppSelector(authState);

   const handleCodeChange = (newCode: string) => {
      if (newCode.length === 0) {
         setError(false);
      }
      setCode(newCode);
      dispatch(addCodeFromEmail(newCode));
   };

   const sendCodeToUser = () => {
      if (email === '') {
         setAlert(true);
      } else {
         dispatch(addEmail(email));
         dispatch(fetchVerificationCode()).then(({ payload }) => {
            console.log(payload);
            if (payload.response?.status !== 400) {
               navigate(routes().resetPassword);
            }
         });
      }
   };

   useEffect(() => {
      const handleKeyPress = (event: KeyboardEvent) => {
         if (event.key === 'Enter') {
            event.preventDefault();
            sendCodeToUser();
         }
      };
      if (inputRef && inputRef.current) {
         inputRef.current.addEventListener('keypress', handleKeyPress);
      }

      return () => {
         if (inputRef && inputRef.current) {
            inputRef.current.removeEventListener('keypress', handleKeyPress);
         }
      };
   }, [email]);

   const getHelperText = () => {
      if (alert) {
         return t('common:saveYourPortfolio.emailErrorMessage');
      }
      if (fetchVerCodeError) {
         return 'Email address was not found';
      }
      return null;
   };

   const backButtonAction = () => {
      if (sessionProfileActivated && JSON.parse(sessionProfileActivated)) {
         navigate(routes().myProfile);
      } else {
         navigate(routes().loginEmail);
      }
   };

   const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newEmail = e.target.value;
      if (newEmail === '') {
         setAlert(false);
      }
      setEmail(newEmail);
   };

   return (
      <PageWrapper leftButton={{ onClick: () => backButtonAction() }}>
         <ContentWrapper>
            <Box component='div' width='100%' display='flex' flexDirection='column' gap={2.5} mb={3.75}>
               <PageMainLabel>{t('forgotPassword.heading')}</PageMainLabel>
               <PageSubLabel>
                  Please enter your email address below. We will send you a code which you will need to enter on the
                  next screen.
               </PageSubLabel>
               <TextField
                  fullWidth
                  required
                  ref={inputRef}
                  autoComplete='off'
                  className='CommonInput'
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  placeholder={t('common:login.inputText1')}
                  type='email'
                  value={email}
                  error={alert}
                  onChange={handleEmailChange}
                  helperText={getHelperText()}
                  sx={{ maxWidth: { xs: '100%', sm: '346px' }, margin: 'auto' }}
               />
            </Box>
            <BottomContentBox isFocused={isFocused}>
               <ButtonFurther endIcon={<Icon name={ENUM_ICON.ARROW_RIGHT} />} onClick={sendCodeToUser}>
                  {t('common:myProfile.changePasswordPopupCTA')}
               </ButtonFurther>
            </BottomContentBox>
         </ContentWrapper>
      </PageWrapper>
   );
}
