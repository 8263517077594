import React, { useState, useEffect } from 'react';
import { Box, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageMainLabel, PageSubLabel, ButtonFurther, BottomContentBox } from 'components/common';
import { routes } from 'routes/routes';
import { Icon } from 'components/icon';
import { ENUM_ICON } from 'enums/icons';
import { PageWrapper } from 'wrappers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addName, sendSignupData, authState } from 'store/auth/auth.slice';

export function NamePage() {
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const [name, setName] = useState('');
   const { t } = useTranslation();

   const { signupData, signupDataStatus } = useAppSelector(authState);

   const handleNext = () => {
      dispatch(addName(name));
      dispatch(sendSignupData());
   };

   useEffect(() => {
      if (signupDataStatus === 'resolved') {
         navigate(routes().causes);
      }
   }, [signupDataStatus]);

   return (
      <PageWrapper leftButton={{ onClick: () => navigate(routes().password) }}>
         <Box
            component='section'
            display='flex'
            flexDirection='column'
            width='100%'
            minHeight='63%'
            justifyContent='space-between'
         >
            <Box component='div' width='100%' display='flex' flexDirection='column' gap={4} mb={3.8}>
               <PageMainLabel>What&apos;s your name?</PageMainLabel>
               <PageSubLabel>Type in your name or how you want us to call you</PageSubLabel>
            </Box>
            <TextField
               fullWidth
               placeholder='FULL NAME'
               type='text'
               className='CommonInput'
               value={name}
               onChange={(e) => setName(e.target.value)}
               sx={{ mb: ({ typography, size }) => typography.pxToRem(size.font_32) }}
            />
            <BottomContentBox>
               <ButtonFurther endIcon={<Icon name={ENUM_ICON.ARROW_RIGHT} />} onClick={handleNext}>
                  {t('common:insertYourCode.buttonCTA')}
               </ButtonFurther>
            </BottomContentBox>
         </Box>
      </PageWrapper>
   );
}
