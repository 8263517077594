import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import CheckIcon from '@mui/icons-material/Check';
import {
   styled,
   Typography,
   TypographyProps,
   ButtonProps,
   Button,
   Box,
   BoxProps,
   Container,
   ContainerProps,
   IconButton,
   IconButtonProps,
} from '@mui/material';
import { hexToRgba } from 'utils/utils';

interface BottonContentBoxProps extends BoxProps {
   isFocused?: boolean;
   focusedValue?: string;
}

interface CustomMenuButtonProps extends BoxProps {
   disabled?: boolean;
}

interface FixedBottomNavProps extends ContainerProps {
   withBorder?: boolean;
}

interface IconCaptionNumberProps extends TypographyProps {
   iconText: string;
}

export const ContentWrapper = styled(({ children, ...props }: BoxProps) => (
   <Box component='section' pb={4} {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   width: '100%',
   height: '100%',
   display: 'flex',
   flexGrow: 1,
   flexDirection: 'column',
   justifyContent: 'space-between',
   alignItems: 'center',
}));

export const BottomContentBox = styled(({ children, isFocused, focusedValue, ...props }: BottonContentBoxProps) => (
   <Box component='div' gap={2.5} {...props}>
      {children}
   </Box>
))(({ theme, isFocused, focusedValue = 'calc(-100% + 300px)' }) => ({
   width: '100%',
   display: 'flex',
   flexDirection: 'column',
   textAlign: 'center',
   position: 'relative',
   transition: 'top 0.2s',
   top: isFocused ? focusedValue : '0',
   '@media(max-height: 600px)': { top: 0 },
   [theme.breakpoints.up('sm')]: {
      top: 0,
   },
}));

export const FixedBottomNav = styled(({ children, withBorder, ...props }: FixedBottomNavProps) => (
   <Container component='div' maxWidth='xs' gap={2.5} {...props}>
      {children}
   </Container>
))(({ theme, withBorder }) => ({
   width: '100%',
   height: '92px',
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'center',
   borderTop: withBorder ? '1px solid rgba(0, 0, 0, 0.10)' : 'none',
   background: withBorder ? hexToRgba(theme.palette.main.pageBackground, 0.8) : 'none',
   backdropFilter: withBorder ? 'blur(2px)' : 'none',
}));

export const PageMainLabel = styled(({ children, ...props }: TypographyProps) => (
   <Typography
      component='h1'
      sx={{
         fontSize: { xs: '34px', sm: '36px' },
         fontWeight: { sm: '900' },
         ml: { xs: '0' },
         mr: { xs: '0' },
         maxWidth: { xs: '100%' },
      }}
      textAlign={{ xs: 'left' }}
      {...props}
   >
      {children}
   </Typography>
))(({ theme }) => ({
   fontWeight: theme.typography.fontWeightBold,
   textTransform: 'none',
   lineHeight: '38px',
   marginBottom: '10px',
}));

export const PageSubLabel = styled(({ children, ...props }: TypographyProps) => (
   <Typography component='h2' fontSize={18} textAlign={{ xs: 'left', sm: 'left' }} {...props}>
      {children}
   </Typography>
))(({ theme }) => ({
   fontWeight: 500,
   color: '#333333',
   lineHeight: '22px',
   '& > span': {
      fontWeight: 700,
   },
}));

export const PageCaption = styled(({ children, ...props }: TypographyProps) => (
   <Typography component='label' fontSize={18} {...props}>
      {children}
   </Typography>
))(({ theme }) => ({
   fontWeight: theme.typography.fontWeightMedium,
   color: theme.palette.gray.light,
}));

export const IconCaption = styled(({ children, ...props }: TypographyProps) => (
   <Typography component='label' fontSize={18} {...props}>
      <div
         style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'nowrap',
         }}
      >
         <CheckIcon
            sx={{
               color: '#0FBD00',
               strokeWidth: 4,
               stroke: 'currentColor',
               marginRight: '10px',
            }}
         />
         <p>{children}</p>
      </div>
   </Typography>
))(({ theme }) => ({
   fontWeight: 500,
   color: '#111111',
   lineHeight: '22px',
}));

export const IconCaptionNumber = styled(({ children, iconText, ...props }: IconCaptionNumberProps) => (
   <Typography component='label' fontSize={18} {...props}>
      <div
         style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'nowrap',
         }}
      >
         <Box
            style={{
               width: '10px',
               height: '10px',
               padding: '10px',
               background: '#5B86F3',
               borderRadius: '100px',
               flexDirection: 'column',
               justifyContent: 'center',
               alignItems: 'center',
               gap: '10px',
               display: 'inline-flex',
               marginRight: '10px',
            }}
         >
            <Typography
               style={{
                  textAlign: 'center',
                  color: 'white',
                  fontSize: '12px',
                  fontWeight: 800,
                  lineHeight: '40px',
                  letterSpacing: '0.32px',
                  wordWrap: 'break-word',
               }}
            >
               {iconText}
            </Typography>
         </Box>
         <p>{children}</p>
      </div>
   </Typography>
))(({ theme }) => ({
   fontWeight: 500,
   color: '#111111',
   lineHeight: 1.3,
   wordWrap: 'break-word',
}));

export const BasicButton = styled(({ children, ...props }: ButtonProps & { fontFamily?: string }) => (
   <Button variant='outlined' {...props}>
      {children}
   </Button>
))(({ fontFamily, theme }) => ({
   fontWeight: theme.typography.fontWeightMedium,
   background: theme.palette.white.main,
   fontSize: `${theme.typography.pxToRem(theme.size.font_16)}`,
   fontFamily: "'Inter', 'Arial', sans-serif",
   textTransform: 'capitalize',
   lineHeight: theme.typography.pxToRem(theme.size.font_20),
   color: theme.palette.black.main,
   minHeight: '48px',
   border: `1px solid ${theme.palette.black.main}`,
   borderRadius: theme.typography.pxToRem(theme.size.font_36),
   '&:hover': {
      border: `1px solid ${theme.palette.black.main}`,
      boxShadow: `${theme.shadows[1]}`,
      background: theme.palette.white.main,
   },
}));

export const IconWrapperBox = styled(({ children, ...props }: BoxProps) => (
   <Box component='div' {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   width: '100%',
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'space-between',
}));

export const BackIconButton = styled(({ children, ...props }: IconButtonProps) => (
   <IconButton {...props}>{children}</IconButton>
))(({ theme }) => ({
   width: theme.typography.pxToRem(48),
   height: theme.typography.pxToRem(48),
   background: theme.palette.black.main,
   '&:hover': {
      background: 'white',
      border: '1px solid black',
      '& path': {
         fill: 'black',
      },
      '& svg line': {
         stroke: 'black',
      },
   },
}));

export const ButtonFurther = styled(({ children, ...props }: ButtonProps) => (
   <Button
      sx={{
         transition: 'all 0.25s linear',
         width: { xs: '100%' },
         marginX: 'auto',
         '&:hover': {
            border: 'none !important',
         },
      }}
      variant='outlined'
      {...props}
   >
      {children}
   </Button>
))(({ theme }) => ({
   background: '#333',
   color: theme.palette.white.main,
   minHeight: '48px',
   minWidth: '155px',
   borderRadius: theme.typography.pxToRem(theme.size.font_36),
   // border: `1px solid ${theme.palette.gray.extraLight}`,
   fontSize: theme.typography.pxToRem(theme.size.font_16),
   lineHeight: theme.typography.pxToRem(theme.size.font_20),
   fontWeight: theme.typography.fontWeightMedium,
   textTransform: 'none',
   '&:hover': {
      background: '#333',
      // border: `1px solid ${theme.palette.gray.extraLight}`,
   },
   '&:active': {
      background: theme.palette.black.main,
      // border: `1px solid ${theme.palette.gray.extraLight}`,
      boxShadow: 'none',
   },
}));

export const HaveLinkText = styled(({ children, ...props }: TypographyProps) => (
   <Typography component='h3' fontSize={16} {...props}>
      {children}
   </Typography>
))(({ theme }) => ({
   color: theme.palette.black.main,
   letterSpacing: '0.28px',
   '& > a': {
      letterSpacing: '0.28px',
      fontSize: theme.size.font_14,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.black.main,
   },
}));

export const HaveLinkTextNotBold = styled(({ children, ...props }: TypographyProps) => (
   <Typography component='h3' fontSize={14} {...props}>
      {children}
   </Typography>
))(({ theme }) => ({
   color: theme.palette.black.main,
   lineHeight: 1,
   textAlign: 'center',
   letterSpacing: '0.28px',
   '& > a': {
      letterSpacing: '0.28px',
      fontSize: theme.size.font_14,
      fontWeight: theme.typography.fontWeightLight,
      color: theme.palette.black.main,
      textTransform: 'none',
   },
}));

export const RegLinkText = styled(({ children, ...props }: TypographyProps) => (
   <Typography component='h3' fontSize={14} {...props}>
      {children}
   </Typography>
))(({ theme }) => ({
   color: theme.palette.black.main,
   letterSpacing: '0.28px',
   '& > a': {
      letterSpacing: '0.28px',
      fontSize: theme.size.font_14,
      fontWeight: theme.typography.fontWeightLight,
      color: theme.palette.black.main,
   },
}));

export const CustomMenuButton = styled(({ children, disabled, ...props }: CustomMenuButtonProps) => (
   <Box component='button' disabled={disabled} borderRadius={1} p={1.25} fontSize={18} {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   width: '100%',
   height: '45px',
   display: 'inline-flex',
   alignItems: 'center',
   justifyContent: 'space-between',
   background: theme.palette.white.main,
   border: `1px solid ${theme.palette.black.main}`,
   color: theme.palette.black.main,
   boxShadow: `-4px 4px 0 ${theme.palette.black.main}`,
   fontWeight: theme.typography.fontWeightMedium,
   fontFamily: 'Inter, sans-serif',
   textTransform: 'none',
   transition: 'all 0.25s linear',
   cursor: 'pointer',
   '&:hover': {
      boxShadow: `-2px 2px 0 ${theme.palette.black.main}`,
   },
   '&:disabled': {
      cursor: 'auto',
      boxShadow: 'none',
   },
}));

export const BottomProfileBox = styled(({ children, ...props }: BoxProps) => (
   <Box component='div' {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   width: '100%',
   position: 'relative',
   bottom: 0,
   [theme.breakpoints.up('sm')]: {
      bottom: '-160px',
   },
   [theme.breakpoints.up('lg')]: {
      bottom: '-40px',
      paddingBottom: '40px',
   },
}));

export const DividerComponent = styled(({ ...props }: BoxProps) => (
   <Box
      sx={{ mx: 'auto' }}
      display='flex'
      alignItems='center'
      justifyContent='flex-start'
      gap={2}
      component='div'
      {...props}
   >
      <Box flex='1 1 0' height='1px' sx={{ background: '#CCCCCC' }} />
      <Typography sx={{ color: '#666666', fontSize: '14px', fontWeight: 400, wordWrap: 'break-word' }}>or</Typography>
      <Box flex='1 1 0' height='1px' sx={{ background: '#CCCCCC' }} />
   </Box>
))(({ theme }) => ({
   width: '100%',
   position: 'relative',
}));
