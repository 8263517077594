import React from 'react';
import { Typography, Box } from '@mui/material';

import { ModalWrapper } from 'wrappers';
import { ButtonFurther } from 'components/common';

import type { BasicModalProps } from './types';

export function BasicModal({ open, onClose, title, description, onNext, buttonLabel }: BasicModalProps) {
   return (
      <ModalWrapper open={open} onClose={onClose} title={title}>
         <Box
            component='div'
            pt={3.75}
            pl={3.75}
            pr={3.75}
            pb={3.75}
            gap={2.5}
            display='flex'
            flexDirection='column'
            alignItems='center'
         >
            <Typography component='p' className='ModalDescription'>
               {description}
            </Typography>
            <ButtonFurther onClick={onNext}>{buttonLabel}</ButtonFurther>
         </Box>
      </ModalWrapper>
   );
}
