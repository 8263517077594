import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import type { CommonSliceTypes } from './types';

const initialState: CommonSliceTypes = {
   profileWasActivated: false,
   portfolioDoneBtn: false,
};

export const commonSlice = createSlice({
   name: 'commonSlice',
   initialState,
   reducers: {},
});

export const commonState = (state: RootState) => state.common;
