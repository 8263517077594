import React, { useState, useEffect } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { PageWrapper } from 'wrappers';
import { IMAGE } from 'enums/images';
import { CheckMarks, CheckedIcon, ShareIcon } from 'components/icon';
// import { ENUM_ICON } from 'enums/icons';
import { routes } from 'routes/routes';
import { authState, fetchUserByAccessToken } from 'store/auth/auth.slice';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { PageMainLabel, PageSubLabel, ContentWrapper } from 'components/common';

// import { causesState } from 'store/causes/causes.slice';
import { bgColors } from 'pages/causes/styles';
import { causesState, fetchSelectedCauses } from 'store/causes/causes.slice';
import { AnalyticsTrackPurchase } from 'utils/utils';
import { CopyBox, SkipButton } from './styles';

const useGlobalStyle = process.env.REACT_APP_BONO_GLOBAL === 'true';

export function GratitudeDisplayPage() {
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   // const screenSE = useMediaQuery('(max-height:830px)');
   const { loggedUserData } = useAppSelector(authState);
   const linkToCopy = `${process.env.REACT_APP_MAIN_BASE_URL}/?ref_id=${loggedUserData?.settings.referralCode}`;
   const profilePage = `/p/${loggedUserData?.publicName}`;
   const [, setReferalLink] = useState('');
   const [alert, setAlert] = useState(false);
   const { selectedCausesList } = useAppSelector(causesState);
   const { t } = useTranslation();

   const location = useLocation();
   const searchParams = new URLSearchParams(location.search);
   const sessionId = searchParams.get('session_id') || '';

   const copyToClipboard = () => {
      navigator.clipboard.writeText(linkToCopy).then(() => {
         setReferalLink(linkToCopy);
         setAlert(true);
         analytics.track('referral_copy');
      });
   };

   const shareLink = () => {
      if (navigator.share) {
         navigator
            .share({
               title: 'Bono - on a mission to fix the world ',
               text: 'I’ve just built my giving portfolio ',
               url: linkToCopy,
            })
            .catch((error) => console.log('Error sharing:', error));
      } else {
         copyToClipboard();
      }
   };

   const selectedCausesData = localStorage.getItem('selectedCauses') || '';
   const convertedCausesList = JSON.parse(selectedCausesData).map((cause: any) => cause.id);
   const items = JSON.parse(sessionStorage.getItem(sessionId) || '[]');

   useEffect(() => {
      dispatch(fetchUserByAccessToken());
      AnalyticsTrackPurchase(sessionId, items, convertedCausesList);
   }, [dispatch]);

   useEffect(() => {
      if (selectedCausesList?.length === 0) {
         dispatch(fetchSelectedCauses());
      }
      document.title = `Thank you! | ${t('siteTitleName')}`;
   }, []);

   return (
      <ContentWrapper sx={{ height: 'max-content', paddingBottom: '10px !important', justifyContent: 'start' }}>
         <Box
            component='div'
            textAlign={{ xs: 'start', sm: 'center' }}
            justifyContent='center'
            display='flex'
            flexDirection='column'
            mb={3.75}
            sx={{ textDecoration: 'none !important' }}
         >
            <PageMainLabel sx={{ textTransform: 'none' }}>{t('common:sharing.heading')}</PageMainLabel>
            <PageSubLabel
               sx={{ fontWeight: '600', fontSize: '18px', color: '#666', textAlign: { xs: 'left', sm: 'center' } }}
            >
               {t('common:sharing.subHeading')}
            </PageSubLabel>
         </Box>

         <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {selectedCausesList !== null &&
               selectedCausesList.map((cause: { title: string; id: number }, index: number) => {
                  return (
                     <Box
                        sx={{
                           fontSize: '14px',
                           fontWeight: '600',
                           padding: '5px 10px',
                           border: useGlobalStyle ? 'none' : '1px solid black',
                           borderRadius: '8px',
                           boxShadow: useGlobalStyle ? 'none' : '-3px 4px black',
                           backgroundColor: bgColors[index % bgColors.length],
                           color: useGlobalStyle ? '#ffffffcc' : '#111',
                        }}
                        key={cause.id}
                     >
                        {cause.title}
                     </Box>
                  );
               })}
         </Box>

         <Box pt={3.75} component='div' gap={1.5} flexDirection='column' display='flex'>
            <Box alignItems='center' flexDirection='row' display='flex'>
               <Box>
                  <CheckMarks />
               </Box>
               <Typography ml={1.25} component='p' fontSize={18} fontWeight={500} lineHeight='22px' color='#111111'>
                  {t('common:gratitude.subText1')}
               </Typography>
            </Box>
            <Box alignItems='center' flexDirection='row' display='flex'>
               <Box>
                  <CheckMarks />
               </Box>
               <Typography ml={1.25} component='p' fontSize={18} fontWeight={500} lineHeight='22px' color='#111111'>
                  {t('common:gratitude.subText2')}
               </Typography>
            </Box>
         </Box>

         <Box
            component='div'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            mb={5.25}
            width='100%'
            pt={6.25}
         >
            <CopyBox
               sx={{
                  justifyContent: 'center !important',
                  padding: '0',
                  width: { xs: '100%', sm: '346px' },
                  borderWidth: '2px',
                  background: '#ffffff',
                  cursor: 'pointer',
                  '&:hover': {
                     borderWidth: '2px',
                  },
               }}
            >
               <IconButton
                  disableRipple
                  sx={{
                     padding: 0,
                     textAlign: 'center',
                     fontSize: '18px',
                     fontWeight: '600',
                     color: 'black !important',
                     gap: '8px',
                  }}
                  onClick={shareLink}
               >
                  <ShareIcon />
                  <Box>{t('common:myProfile.menuItem2')}</Box>
               </IconButton>
               {alert && (
                  <Typography
                     component='p'
                     fontSize={16}
                     fontWeight={600}
                     color='#3CD39D'
                     sx={{ position: 'absolute', top: '48px', left: 0, right: 0 }}
                  >
                     {t('common:linkCopied')}
                  </Typography>
               )}
            </CopyBox>

            <Box component='div' marginTop='20px'>
               <SkipButton onClick={() => navigate(profilePage)}>{t('sharing.link')}</SkipButton>
            </Box>
         </Box>
      </ContentWrapper>
   );
}
