/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum IMAGE {
   SLIDER = '/images/slider.png',
   MAIN_LOGO = '/images/main_bono_logo.png',
   SVG_LOGO = '/images/logo.svg',
   PRO_ISRAEL = '/images/pro_israel_logo.svg',
   PRO_ISRAEL_white = '/images/pro_israel_logo_white.svg',

   SMALL_SIGNATURE = '/images/bono_signature_small.png',
   BIG_SIGNATURE = '/images/bono_signature.png',

   SMALL_SEAL = '/images/bono_seal_small.png',
   BIG_SEAL = '/images/bono_seal.png',

   FEED_IMG = '/images/feed_image.png',

   STAND_WITH_ISRAEL = '/images/modal.png',
   FCK_VIOLENCE_SHARE = '/images/Fck_viol_share.png',
   HUGE_IMPACT = 'images/huge_impact.png',
   BONO_LOGO = 'images/bonoLogo.png',
   BONO_LOGO_walk = 'images/logo 26_12.svg',
   PRO_ISRAEL_LIGHT = 'images/logo-proisrael.svg',
}
