import React from 'react';
import { styled, Link, LinkProps, Typography, TypographyProps } from '@mui/material';

export const CharityShortDescription = styled(({ children, ...props }: TypographyProps) => (
   <Typography component='p' fontSize={16} {...props}>
      {children}
   </Typography>
))(({ theme }) => ({
   fontWeight: theme.typography.fontWeightMedium,
   paddingLeft: '12px',
}));

export const CharityLink = styled(({ children, ...props }: LinkProps) => (
   <Link fontSize={18} target='_blank' rel='noopener noreferrer' {...props}>
      {children}
   </Link>
))(({ theme }) => ({
   fontWeight: theme.typography.fontWeightMedium,
   color: theme.palette.black.main,
   textTransform: 'lowercase',
   textDecoration: 'none',
}));
